import React, { useState, useEffect } from "react";
import { StaticQuery, graphql } from "gatsby";
import ProductCard from "./ProductCard";
import Grid, { GridConfig } from "./Grid";

interface ProductGridProps {
  products: any[];
  theme: {
    classNames: string;
    imageClassNames: string;
    priceClassNames: string;
    titleClassNames: string;
    gridConfig: GridConfig;
  };
}

const ProductGrid = ({
  products,
  theme: {
    classNames,
    imageClassNames,
    priceClassNames,
    titleClassNames,
    gridConfig,
  },
}: ProductGridProps) => {
  return (
    <section className={`ProductGrid ${classNames}`}>
      <Grid grid={gridConfig} className={classNames}>
        {products &&
          products.map((p, i) => {
            return (
              <ProductCard
                key={`product-${p.id}-${i}`}
                {...p}
                theme={{
                  imageClassNames: imageClassNames,
                  priceClassNames: priceClassNames,
                  titleClassNames: titleClassNames,
                }}
              />
            );
          })}
      </Grid>
    </section>
  );
};

// const ProductGrid = ({
//   products,
//   classNames,
//   imageClassNames,
//   priceClassNames,
//   titleClassNames,
//   gridConfig,
//   tagName,
// }) => (
//   <StaticQuery
//     query={graphql`
//       query ProductGridQuery {
//         allPages(filter: {categories: {}, tags: {in: "${tagName}"}}) {
//           edges {
//             node {
//               id
//             }
//           }
//         }
//       }
//     `}
//     render={(data) => (
//       <ProductGridStatic
//         products={products}
//         classNames={classNames}
//         imageClassNames={imageClassNames}
//         priceClassNames={priceClassNames}
//         titleClassNames={titleClassNames}
//         gridConfig={gridConfig}
//         data={data}
//       />
//     )}
//   />
// );

export default ProductGrid;

// to, title, price, imgSrc, imgClassNames, titleClassNames, priceClassNames
