import React from "react"
import Grid from "./Grid";
const faqs = [
    {
      question: 'How do you make holy water?',
      answer:
        'You boil the hell out of it. Lorem ipsum dolor sit amet consectetur adipisicing elit. Quas cupiditate laboriosam fugiat.',
    },
    {
        question: 'How do you make holy water?',
        answer:
          'You boil the hell out of it. Lorem ipsum dolor sit amet consectetur adipisicing elit. Quas cupiditate laboriosam fugiat.',
      },
      {
        question: 'How do you make holy water?',
        answer:
          'You boil the hell out of it. Lorem ipsum dolor sit amet consectetur adipisicing elit. Quas cupiditate laboriosam fugiat.',
      },
      {
        question: 'How do you make holy water?',
        answer:
          'You boil the hell out of it. Lorem ipsum dolor sit amet consectetur adipisicing elit. Quas cupiditate laboriosam fugiat.',
      },
      {
        question: 'How do you make holy water?',
        answer:
          'You boil the hell out of it. Lorem ipsum dolor sit amet consectetur adipisicing elit. Quas cupiditate laboriosam fugiat.',
      },
      {
        question: 'How do you make holy water?',
        answer:
          'You boil the hell out of it. Lorem ipsum dolor sit amet consectetur adipisicing elit. Quas cupiditate laboriosam fugiat.',
      }
    // More questions...
  ]

const FAQItem = ({question, answer}) => {
    return (
        <div className="FAQItem">
            <dt className="text-lg leading-6 font-medium text-gray-900">{question}</dt>
            <dd className="mt-2 text-base text-gray-500">{answer}</dd>
        </div>
    )
}

const gridConfig = {
    sm: [1, 0],
    md: [1, 0],
    lg: [3, 8],
    default: [],
}
  
const FAQ =({title, questions}) => {
    return (
      <section className="FAQ">
        <div className="FAQ__container">
          <Grid grid={gridConfig} className={'mx-auto'}>
            <div>
              <h2 className="FAQ__title">{title}</h2>
              {/* Markdown Block */}
            </div>
            <div className="FAQItem__container">
              <dl className="space-y-12">
                {faqs.map((faq, i) => (
                    <FAQItem answer={faq.answer} question={faq.question} key={`${i}-faq`}/>
                ))}
              </dl>
            </div>
          </Grid>
        </div>
      </section>
    )
  }

  export default FAQ;