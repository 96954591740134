import React, { Fragment } from "react";
import ContentBlock from "./ContentBlock";
import Grid from "./Grid";
import Footer from "./Footer";
import NavbarSimple from "./NavbarSimple";
import { useStaticQuery, graphql } from "gatsby";

const DefaultBody = ({ children }) => {
  return <>{children}</>;
};

const GridBody = ({ children }) => {
  return <Grid>{children}</Grid>;
};

// Need to generate a layout based on the theme type.
// Layout Types: multi column content, single column content, application body

const Layout = ({ children }) => {
  const { site } = useStaticQuery(query);
  const { siteMetadata } = site;

  // console.log(siteMetadata);
  return (
    <div
      className={`Layout min-h-screen max-w-full overflow-x-hidden bg-${
        siteMetadata.layoutTheme.lightBg
      } dark:bg-${siteMetadata.layoutTheme.darkBg} ${
        siteMetadata.layoutTheme.name
      } ${siteMetadata.layoutTheme.variations.join(" ")}`}
    >
      <main>
        <NavbarSimple config={siteMetadata.headerNav} />
        {children}
      </main>
      <Footer config={siteMetadata.footerNav} />
    </div>
  );
};

export default Layout;

const query = graphql`
  {
    site {
      siteMetadata {
        footerNav {
          shouldShowFooterNav
          copyrightText
          legalLinks {
            id
            linkText
            newWindow
            to
            theme {
              classNames
            }
          }
          linkBlocks {
            theme {
              blockClassNames
              linkClassNames
            }
            description
            links {
              id
              linkText
              newWindow
              to
              theme {
                classNames
              }
            }
            title
          }
          theme {
            name
            navLink {
              classNames
            }
            socialRow {
              classNames
              iconClassNames
            }
            legalText {
              shouldShowLegalText
              classNames
            }
            footerClassNames
          }
          socialIcons {
            uri
            iconName
          }
        }
        bodyType {
          columnSpacing
          columns {
            classNames
            colspan
            order
          }
          type
        }
        description
        headerNav {
          links {
            id
            linkText
            newWindow
            to
            theme {
              classNames
            }
          }
          logoLinkDestination
          logoUri
          shouldShowHeaderNav
          theme {
            logoClassNames
            headerClassNames
            headerContainerClassNames
            desktopLinkClassNames
            mobileLinkClassNames
            activeDesktopLinkClassNames
            activeMobileLinkClassNames
            navbarType
            mobileMenuIconOpen
            mobileMenuIconClosed
          }
        }
        layoutTheme {
          baseSpacing
          darkBg
          lightBg
          name
          variations
        }
        name
        seo {
          title
          titleTemplate
          description
          url
          image
          twitterUsername
        }
        socialAccounts {
          platform
          priority
          showInNav
          userName
          webUri
        }
      }
    }
  }
`;
