import React from "react";

const pages = [
  { name: "Projects", href: "#", current: false },
  { name: "Project Nero", href: "#", current: true },
];

const RootBreadCrumb = () => {
  return (
    <li className="RootBreadCrumb flex">
      <div className="flex items-center">
        <a href="#" className="text-gray-400 hover:text-gray-500">
          {/* <HomeIcon className="flex-shrink-0 h-5 w-5" aria-hidden="true" /> */}
          <span className="sr-only">Home</span>
        </a>
      </div>
    </li>
  );
};

const BreadCrumb = ({ page }) => {
  return (
    <li key={page.name} className="NextBreadCrumb flex">
      <div className="flex items-center">
        <svg
          className="flex-shrink-0 w-6 h-full text-gray-200"
          viewBox="0 0 24 44"
          preserveAspectRatio="none"
          fill="currentColor"
          xmlns="http://www.w3.org/2000/svg"
          aria-hidden="true"
        >
          <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
        </svg>
        <a
          href={page.href}
          className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700"
          aria-current={page.current ? "page" : undefined}
        >
          {page.name}
        </a>
      </div>
    </li>
  );
};

interface BreadCrumbLink {
  to: string;
  displayText: string;
  theme: {
    classNames: string;
  };
}

enum SeparatorTypes {
  "less_than",
  "forward_slash",
  "none",
}

interface BreadCrumbsProps {
  theme: {
    classNames: string;
    breadCrumbClassnames: string;
  };
  breadcrumbs: BreadCrumbLink[];
  separator: SeparatorTypes;
}

const BreadCrumbs = ({
  theme: { classNames, breadCrumbClassNames },
  breadcrumbs,
  separator,
}: BreadCrumbsProps) => {
  return (
    <nav className="Breadcrumbs flex" aria-label="Breadcrumb">
      <ol
        role="list"
        className="bg-white rounded-md shadow px-6 flex space-x-4"
      >
        <RootBreadCrumb />
        {pages.map((page) => (
          <BreadCrumb page={page} />
        ))}
      </ol>
    </nav>
  );
};

export default BreadCrumbs;
