import GatsbyLink from "gatsby-link";
import React from "react";
import Grid, { GridConfig } from "./Grid";
import Icon from "./Icon";

interface SocialIconBlockProps {
  theme: {
    classNames: string;
    iconClassNames: string;
  };
  platform: string;
  displayName: string;
  accountUrl: string;
}

interface SocialIcon {
  platform: string;
  displayName: string;
  accountUrl: string;
}

const SocialIconBlock = ({
  platform,
  displayName,
  accountUrl,
  theme: { iconClassNames, classNames },
}: SocialIconBlockProps) => {
  return (
    <div className={`SocialIconBlock ${classNames}`}>
      <a href={accountUrl} target="_BLANK" className="mx-auto">
        <Icon
          variation={"logo"}
          name={platform}
          classNames={`fill-current ${iconClassNames}`}
        />
        <span className={`SocialIconBlock__handle`}>{displayName}</span>
      </a>
    </div>
  );
};

const Title = ({ title }) => {
  return <p>{title}</p>;
};

interface CTASocialProps {
  theme: {
    classNames: string;
    gridConfig: GridConfig;
    iconBlockClassNames: string;
    iconClassNames: string;
    titleClassNames: string;
  };
  title: string;
  socialIcons: SocialIconBlockProps[];
}

const CTASocial = ({
  theme: {
    gridConfig,
    titleClassNames,
    iconBlockClassNames,
    iconClassNames,
    classNames,
  },
  title,
  socialIcons,
}: CTASocialProps) => {
  return (
    <section className={`CTASocial ${classNames}`}>
      <p className={`CTATitle ${titleClassNames}`}>{title}</p>
      <Grid grid={gridConfig} theme={{ classNames: "" }}>
        {socialIcons &&
          socialIcons.map((b: SocialIcon, i: number) => {
            return (
              <SocialIconBlock
                key={`icon-block-${i}`}
                {...b}
                theme={{ iconClassNames, classNames: iconBlockClassNames }}
              />
            );
          })}
      </Grid>
    </section>
  );
};

export default CTASocial;
