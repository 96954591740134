import React from "react";

interface HeroProps {
  theme: {
    classNames: string;
    bgColor: string;
    darkBgColor: string;
  };
  children?: React.ReactNode;
  bgImg: string;
}

const Hero = ({
  bgImg,
  children,
  theme: { classNames, bgColor, darkBgColor },
}: HeroProps): JSX.Element => {
  return (
    <section
      className={`Hero relative bg-${bgColor} dark:bg-${
        darkBgColor ? darkBgColor : bgColor
      } ${classNames}`}
    >
      <div className="Hero__bg-container overflow-hidden">
        {bgImg ? <img className="" src={bgImg} alt="" /> : null}
        <div
          className={`Hero__bg-overlay mix-blend-multiply bg-${bgColor} dark:bg-${
            darkBgColor ? darkBgColor : bgColor
          }`}
          aria-hidden="true"
        ></div>
      </div>
      <div className={`Hero__container h-full`}>{children}</div>
    </section>
  );
};

// Hero.defaultProps = {
//   bgImg: "",
//   theme: {
//     bgColor: "",
//     darkBgColor: "",
//     classNames: "",
//   },
// };

export default Hero;

// Hero 2

// const Hero2 = ({
//     bgImg,
//     children,
//     classNames,
//     overlayClasses,
//     containerClasses,
//   }) => {
//     return (
//       <section className={`Hero relative ${classNames}`}>
//         <div className="Hero__bg-container overflow-hidden">
//           {bgImg ? <img className="" src={bgImg} alt="" /> : null}
//           <div
//             className={`Hero__bg-overlay mix-blend-multiply ${overlayClasses}`}
//             aria-hidden="true"
//           ></div>
//         </div>
//         <div className={`Hero__container h-full ${containerClasses}`}>
//           {children}
//         </div>
//       </section>
//     );
//   };

//   export default Hero;
