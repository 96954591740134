import React, { Fragment } from "react";
import Hero from "./Hero";

interface SplashHeroProps {
    theme: {
        classNames: string;
        bgColor: string;
        darkBgColor: string;
        titleClassNames: string;
        subtitleClassNames: string;
        heroClassNames: string;
    };
    bgImg: string;
    title: string;
    subtitle: string;
}

const SplashHero = ({
    bgImg,
    title,
    subtitle,
    theme: {
        bgColor,
        darkBgColor,
        titleClassNames,
        subtitleClassNames,
        heroClassNames,
    },
}: SplashHeroProps): JSX.Element => {
    return (
        <Hero
            theme={{
                bgColor: bgColor,
                darkBgColor: darkBgColor,
                classNames: heroClassNames,
            }}
            bgImg={bgImg}
            size=""
        >
            <h1 className={`SplashHero__title ${titleClassNames} `}>{title}</h1>
            <p className={`SplashHero__subtitle ${subtitleClassNames} `}>
                {subtitle}
            </p>
        </Hero>
    );
};

export default SplashHero;
