import React, { useState } from "react";
import { Link } from "gatsby";
import Icon from "./Icon";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const NavLink = () => {
  return <div>ABC</div>;
};

interface Link {}

// {theme, links, logoUri, logoLinkDestination, headerClassNames}
interface NavbarSimpleProps {
  config: {
    theme: {
      headerClassNames: string;
      headerContainerClassNames: string;
      logoClassNames: string;
      desktopLinkClassNames: string;
      mobileLinkClassNames: string;
    };
    links: Link[];
    logoImage: string;
    logoLinkDestination: string;
    shouldShowHeaderNav: boolean;
  };
}

const NavbarSimple = ({ config }: NavbarSimpleProps) => {
  const { theme, links, logoUri, logoLinkDestination, shouldShowHeaderNav } =
    config;
  const {
    headerClassNames,
    headerContainerClassNames,
    logoClassNames,
    desktopLinkClassNames,
    mobileLinkClassNames,
    activeDesktopLinkClassNames,
    activeMobileLinkClassNames,
    navbarType,
    mobileMenuIconClosed,
    mobileMenuIconOpen,
  } = theme;
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  return (
    <nav
      className={`NavbarSimple relative z-40 ${theme.name}  ${headerClassNames}`}
    >
      <>
        <div className={`NavbarContainer ${headerContainerClassNames}`}>
          <div className="flex justify-between h-20">
            <div className="flex w-full">
              <div className="LogoBlock flex-shrink-0 flex items-center">
                <Link to="/">
                  <Icon
                    name="logo"
                    variation="site"
                    classNames={`fill-current ${logoClassNames}`}
                  />
                </Link>
              </div>
              <div className="hidden ml-auto sm:flex sm:space-x-8 items-center flex-grow">
                {links.map((l, i) => {
                  return (
                    <Link
                      key={`${i}-${l.id}`}
                      to={l.to}
                      className={`NavbarSimpleLink Desktop ${
                        i === 0 ? "ml-auto" : ""
                      } ${desktopLinkClassNames}`}
                      activeClassName={activeDesktopLinkClassNames}
                    >
                      {l.linkText}
                    </Link>
                  );
                })}
              </div>
            </div>

            <div className="-mr-2 flex items-center sm:hidden">
              {/* Mobile menu button */}
              <button
                className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none"
                onClick={() => {
                  setIsMenuOpen(!isMenuOpen);
                }}
              >
                <span className="sr-only">Open main menu</span>
                {isMenuOpen ? (
                  <Icon name={mobileMenuIconClosed} variation="solid" />
                ) : (
                  <Icon name={mobileMenuIconOpen} variation="solid" />
                )}
              </button>
            </div>
          </div>
        </div>

        <div className={`sm:hidden ${isMenuOpen ? "block " : "hidden "}`}>
          <div className="pt-2 pb-3 space-y-1 shadow-2xl">
            {links.map((l, i) => {
              return (
                <Link
                  key={`i-${l.id}`}
                  to={l.to}
                  className={`NavbarSimpleLink Mobile ${mobileLinkClassNames}`}
                  activeClassName={activeMobileLinkClassNames}
                >
                  {l.linkText}
                </Link>
              );
            })}
          </div>
        </div>
      </>
    </nav>
  );
};

// const NavbarSimple = ({ config }) => {
//   const { theme, links, logoUri, logoLinkDestination, headerClassNames } =
//     config;
//   const [isMenuOpen, setIsMenuOpen] = useState(false);
//   return (
//     <Disclosure
//       as="nav"
//       className={`NavbarSimple relative z-40 ${
//         theme.name
//       } ${theme.variations.join(" ")} bg-${theme.lightBg} dark:bg-${
//         theme.darkBg
//       } ${headerClassNames}`}
//     >
//       {({ isMenuOpen }) => (
//         <>
//           <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
//             <div className="flex justify-between h-20">
//               <div className="flex w-full">
//                 <div className="LogoBlock flex-shrink-0 flex items-center">
//                   <Link to="/">
//                     <Icon
//                       name="logo"
//                       variation="site"
//                       classNames={`fill-current ${theme?.logoClassNames}`}
//                     />
//                   </Link>
//                 </div>
//                 <div className="hidden ml-auto sm:flex sm:space-x-8 items-center flex-grow flex">
//                   {links.map((l, i) => {
//                     return (
//                       <Link
//                         key={`${i}-${l.id}`}
//                         to={l.to}
//                         className={`NavbarSimpleLink text-${
//                           theme.navLink.color
//                         } hover:text-${theme.navLink.hoverColor} dark:text-${
//                           theme.navLink.darkmodeColor
//                         } dark:hover:text-${theme.navLink.darkmodeHoverColor} ${
//                           i === 0 ? "ml-auto" : ""
//                         } border-transparent inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium`}
//                         activeClassName="border-indigo-500 dark:border-yellow-500"
//                       >
//                         {l.linkText}
//                       </Link>
//                     );
//                   })}
//                 </div>
//               </div>

//               <div className="-mr-2 flex items-center sm:hidden">
//                 {/* Mobile menu button */}
//                 <Disclosure.Button className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none">
//                   <span className="sr-only">Open main menu</span>
//                   {isMenuOpen ? (
//                     <Icon name="x" variation="solid" />
//                   ) : (
//                     <Icon name="menu_alt_2" variation="solid" />
//                   )}
//                 </Disclosure.Button>
//               </div>
//             </div>
//           </div>

//           <Disclosure.Panel className="sm:hidden">
//             <div className="pt-2 pb-3 space-y-1 shadow-2xl">
//               {links.map((l, i) => {
//                 return (
//                   <Link
//                     key={`i-${l.id}`}
//                     to={l.to}
//                     className={`NavbarSimpleLink text-${theme.navLink.color} hover:text-${theme.navLink.hoverColor} dark:text-${theme.navLink.darkmodeColor} dark:hover:text-${theme.navLink.darkmodeHoverColor} border-transparent  block pl-3 p-2 pr-4  border-l-4 text-base font-medium`}
//                     activeClassName="border-indigo-500 dark:border-yellow-500"
//                   >
//                     {l.linkText}
//                   </Link>
//                 );
//               })}
//             </div>
//           </Disclosure.Panel>
//         </>
//       )}
//     </Disclosure>
//   );
// };

export default NavbarSimple;

// const NotificationIcon = () => {
//   return (
//     <div className="hidden sm:ml-6 sm:flex sm:items-center">
//       <button
//         type="button"
//         className="bg-white p-1 rounded-full text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
//       >
//         <span className="sr-only">View notifications</span>
//       </button>
//     </div>
//   );
// };

// const MobileUserIcon = () => {
//     return (
//         <div className="pt-4 pb-3 border-t border-gray-200">
//                 <div className="flex items-center px-4">
//                     <div className="flex-shrink-0">
//                     <img
//                         className="h-10 w-10 rounded-full"
//                         src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
//                         alt=""
//                     />
//                     </div>
//                     <div className="ml-3">
//                     <div className="text-base font-medium text-gray-800">Tom Cook</div>
//                     <div className="text-sm font-medium text-gray-500">tom@example.com</div>
//                     </div>
//                     <button
//                     type="button"
//                     className="ml-auto flex-shrink-0 bg-white p-1 rounded-full text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
//                     >
//                     <span className="sr-only">View notifications</span>
//                     {/* <BellIcon className="h-6 w-6" aria-hidden="true" /> */}
//                     </button>
//                 </div>
//                 <div className="mt-3 space-y-1">
//                     <Disclosure.Button
//                     as="a"
//                     href="#"
//                     className="block px-4 py-2 text-base font-medium text-gray-500 hover:text-gray-800 hover:bg-gray-100"
//                     >
//                     Your Profile
//                     </Disclosure.Button>
//                     <Disclosure.Button
//                     as="a"
//                     href="#"
//                     className="block px-4 py-2 text-base font-medium text-gray-500 hover:text-gray-800 hover:bg-gray-100"
//                     >
//                     Settings
//                     </Disclosure.Button>
//                     <Disclosure.Button
//                     as="a"
//                     href="#"
//                     className="block px-4 py-2 text-base font-medium text-gray-500 hover:text-gray-800 hover:bg-gray-100"
//                     >
//                     Sign out
//                     </Disclosure.Button>
//                 </div>
//                 </div>
//     )
// }

// const UserIconList = () => {
//     return (
// {/* Profile dropdown */}
// <Menu as="div" className="ml-3 relative">
// <div>
//     <Menu.Button className="bg-white rounded-full flex text-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
//     <span className="sr-only">isMenuOpen user menu</span>
//     <img
//         className="h-8 w-8 rounded-full"
//         src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
//         alt=""
//     />
//     </Menu.Button>
// </div>

//         <Transition
//         as={Fragment}
//         enter="transition ease-out duration-200"
//         enterFrom="transform opacity-0 scale-95"
//         enterTo="transform opacity-100 scale-100"
//         leave="transition ease-in duration-75"
//         leaveFrom="transform opacity-100 scale-100"
//         leaveTo="transform opacity-0 scale-95"
//     >
//         <Menu.Items className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">

//         {links.map((l, i) => {
//             return (
//                 <Menu.Item>
//                     <Link
//                         key={`i-${l.id}`}
//                         to={l.to}
//                     className={`NavbarSimpleLink text-${l.color} hover:text-${l.hoverColor} dark:text-${l.darkmodeColor} dark:hover:text-${l.darkmodeHoverColor} border-transparent hover:border-gray-300  inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium`}
//                     activeClassName='border-indigo-500'>{l.linkText}</Link>
//                 </Menu.Item>
//             )
//         })}
//             {({ active }) => (
//             <a
//                 href="#"
//                 className={classNames(active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700')}
//             >
//                 Your Profile
//             </a>
//             )}

//         <Menu.Item>
//             {({ active }) => (
//             <a
//                 href="#"
//                 className={classNames(active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700')}
//             >
//                 Settings
//             </a>
//             )}
//         </Menu.Item>
//         <Menu.Item>
//             {({ active }) => (
//             <a
//                 href="#"
//                 className={classNames(active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700')}
//             >
//                 Sign out
//             </a>
//             )}
//         </Menu.Item>
//         </Menu.Items>
//     </Transition>
// </Menu>
//     )
// }
