import React from "react";

interface TypographyProps {
  theme: {
    classNames?: string;
  };
  componentType: string;
  value: string;
}

const Typography = ({
  theme: { classNames },
  componentType,
  value,
}: TypographyProps) => {
  const Comp = componentType;
  return <Comp className={classNames}>{value}</Comp>;
};

export default Typography;
