import React from "react";
import Grid, { GridConfig } from "./Grid";

interface GalleryItemProps {
  id: string;
  imgSrc: string;
  caption?: string;
  title?: string;
  theme: {
    classNames?: string;
    imageClassNames?: string;
  };
}

const GalleryItem = ({
  id,
  imgSrc,
  caption,
  title,
  theme: { classNames, imageClassNames },
}: GalleryItemProps) => {
  return (
    <div className={`GalleryItem flex ${classNames} ${imageClassNames}`}>
      <img src={imgSrc} className="object-cover" alt={`${title}: ${caption}`} />
    </div>
  );
};

// const GalleryItemFeatured = () => {
//   return (
//     <div className="w-full col-span-2 row-span-2 rounded">
//       <img
//         src="https://images.unsplash.com/photo-1523275335684-37898b6baf30?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=989&q=80"
//         alt="image"
//       />
//     </div>
//   );
// };

interface ImageObject {
  id: string;
  imgSrc: string;
  caption?: string;
  title?: string;
  theme: {
    imageClassNames: string;
  };
}

interface ImageGalleryProps {
  images: ImageObject[];
  theme: {
    classNames?: string;
    galleryItemClassNames?: string;
    gridConfig: GridConfig;
  };
}

const ImageGallery = ({
  images,
  theme: { classNames, galleryItemClassNames, gridConfig },
}: ImageGalleryProps) => {
  return (
    <section className={`ImageGallery ${classNames}`}>
      <Grid grid={gridConfig} theme={{}}>
        {images.map((img: ImageObject, i: number) => {
          return (
            <GalleryItem
              key={`GalleryItem_${i}`}
              imgSrc={img.imgSrc}
              caption={img.caption}
              title={img.title}
              id={img.id}
              theme={{
                classNames: galleryItemClassNames,
                imageClassNames: img?.theme?.imageClassNames,
              }}
            />
          );
        })}

        {/* <GalleryItemFeatured /> */}
      </Grid>
    </section>
  );
};

export default ImageGallery;
