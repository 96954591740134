import React from "react";

const BackgroundImageColorOverlay = ({ bgImage, overlayClassNames }) => {
  return (
    <div className="BackgroundImageColorOverlay">
      <img
        className="BackgroundImageColorOverlay__image"
        src={bgImage}
        alt=""
      />
      <div
        className={`BackgroundImageColorOverlay__color-overlay ${overlayClassNames} mix-blend-multiply`}
        aria-hidden="true"
      />
    </div>
  );
};

interface Header2Props {
  theme: {
    classNames: string;
    titleClassNames: string;
    subtitleClassNames: string;
    overlayClassNames: string;
  };
  image: string;
  title: string;
  subtitle: string;
}

const Header2 = ({
  title,
  subtitle,
  image,
  theme: { classNames, titleClassNames, overlayClassNames, subtitleClassNames },
}: Header2Props) => {
  return (
    <div className={`PageHeader2 ${classNames}`}>
      <BackgroundImageColorOverlay
        theme={"primary"}
        bgImage={image}
        overlayClassNames={overlayClassNames}
      />
      <div className="PageHeader2__title-container ">
        <h1 className={`PageHeader2__title ${titleClassNames}`}>{title}</h1>
        <p className={`PageHeader2__subtitle ${subtitleClassNames}`}>
          {subtitle}
        </p>
      </div>
    </div>
  );
};

export default Header2;
