import React, { useState, useEffect } from "react";

interface FaderImage {
  uri: string;
  id: string;
  description?: string;
  title?: string;
}

interface ImageCrossFaderProps {
  images: FaderImage[];
  interval: number;
  theme: {
    classNames?: string;
    imageClassNames?: string;
  };
}

const isActiveImage = (imgIndex: number, activeImgIndex: number): boolean => {
  return imgIndex === activeImgIndex;
};

const isLastImage = (imgIndex: number, listLength: number): boolean => {
  return imgIndex === listLength;
};

const isPreviousImage = (
  imgIndex: number,
  activeImgIndex: number,
  listLength: number
): boolean => {
  const previousImageIndex = activeImgIndex - 1;
  if (previousImageIndex === imgIndex) {
    return true;
  }

  if (previousImageIndex < 0 && isLastImage(imgIndex, listLength)) {
    return true;
  }
  return false;
};

const generateClassNames = (
  imgIndex: number,
  activeImgIndex: number,
  listLength: number
): string => {
  const variations = {
    top: "inline-block animate__fadeIn animate__slow",
    bottom: "inline-block animate__fadeOut animate__slow",
    hidden: "hidden",
  };
  if (isActiveImage(imgIndex, activeImgIndex)) {
    return variations.top;
  }

  if (isPreviousImage(imgIndex, activeImgIndex, listLength)) {
    return variations.bottom;
  }

  return variations.hidden;
};

const ImageCrossFader = ({
  images,
  interval,
  theme: { classNames, imageClassNames },
}: ImageCrossFaderProps) => {
  const [currentImage, setCurrentImage] = useState(0);
  const len = images.length;

  useEffect(() => {
    const tick = setInterval(() => {
      const nextImage = currentImage + 1;
      if (nextImage >= len) {
        setCurrentImage(0);
      } else {
        setCurrentImage(nextImage);
      }
    }, interval);

    return () => {
      //   console.log(`clearing interval`);
      clearInterval(tick);
    };
  }, [currentImage]);
  return (
    <section className={`ImageCrossFader relative ${classNames}`}>
      <div className="absolute w-full h-full">
        {images.map((img, i) => {
          return (
            <div
              key={`${i}_${img.id}_CrossFaderImage`}
              className={`absolute top-0 left-0 bottom-0 right-0 animate__animated ${imageClassNames} ${generateClassNames(
                i,
                currentImage,
                len
              )}`}
              style={{
                backgroundImage: `url(${img.uri})`,
                backgroundPosition: "center center",
                backgroundSize: "cover",
              }}
            ></div>
          );
        })}
      </div>
    </section>
  );
};

export default ImageCrossFader;
