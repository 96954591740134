import React from "react";

interface AvatarProps {
  imageUrl: string;
  name: string;
  theme: {
    classNames: string;
    imageClassNames: string;
  };
}

const Avatar = ({
  imageUrl,
  name,
  theme: { classNames, imageClassNames },
}: AvatarProps) => {
  return (
    <div className={`flex-shrink-0 ${classNames}`}>
      <img
        className={`h-6 w-6 rounded-full ${imageClassNames}`}
        src={imageUrl}
        alt={`${name} Avatar`}
      />
    </div>
  );
};

export default Avatar;
