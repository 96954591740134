import React, { Fragment } from "react";

import {
  ArticleBlock,
  BreadCrumbs,
  CTA,
  CTASocial,
  DataCaptureBlock,
  FAQ,
  FeatureBlock,
  Header,
  Header2,
  Hero,
  ImageBlock,
  ImageCrossFader,
  ImageGallery,
  LogoCloud,
  // Newsletter,
  OffsetFeatureListBlock,
  Pagination,
  ProductCard,
  ProductGrid,
  SplashHero,
  SplitImageBlock,
  SplitImageFadeBlock,
  SpotifyEmbed,
  Surface,
  // Testimonial,
  // Testimonial2,
  // Testimonial3,
  // ThreeColumnFeature,
  // ThreeColumnFeatureBlock,
  // Timeline,
  TitleCardBlock,
  Typography,
} from "./index";

const components = {
  article_block: ArticleBlock,
  breadcrumbs: BreadCrumbs,
  cta: CTA,
  cta_social: CTASocial,
  data_capture_block: DataCaptureBlock,
  faq: FAQ,
  feature_block: FeatureBlock,
  header: Header,
  header2: Header2,
  hero: Hero,
  image_block: ImageBlock,
  image_cross_fader: ImageCrossFader,
  image_gallery: ImageGallery,
  logo_cloud: LogoCloud,
  // newsletter: Newsletter,
  offset_feature_list_block: OffsetFeatureListBlock,
  pagination: Pagination,
  product_card: ProductCard,
  product_grid: ProductGrid,
  splash_hero: SplashHero,
  split_image_block: SplitImageBlock,
  split_image_fade_block: SplitImageFadeBlock,
  spotify_embed: SpotifyEmbed,
  surface: Surface,
  // testimonial: Testimonial,
  // testimonial2: Testimonial2,
  // testimonial3: Testimonial3,
  // three_column_feature: ThreeColumnFeature,
  // three_column_feature_block: ThreeColumnFeatureBlock,
  // timeline: Timeline,
  title_card_block: TitleCardBlock,
  typography: Typography,
};

// const blockTheme = {
//
// }

// const proptypes =  {
// blockType: "snake_case_name",
// theme: {
//
// },
// blockProps: {
// JSON SCHEMA VALIDATED PROPS DERIVED FROM NON-THEME
// }
// }

enum BlockTypes {
  "article_block",
  "breadcrumbs",
  "cta",
  "cta_social",
  "data_capture_block",
  "faq",
  "feature_block",
  "header",
  "header2",
  "hero",
  "image_block",
  "image_cross_fader",
  "image_gallery",
  "logo_cloud",
  "newsletter",
  "offset_feature_list_block",
  "pagination",
  "product_card",
  "product_grid",
  "splash_hero",
  "split_image_block",
  "split_image_fade_block",
  "spotify_embed",
  "surface",
  "testimonial",
  "testimonial2",
  "testimonial3",
  "three_column_feature",
  "three_column_feature_block",
  "timeline",
  "title_card_block",
  "typography",
}

interface ContentBlockProps {
  id?: string;
  blockType: BlockTypes;
  componentProps: any;
}

const ContentBlock = ({ blockType, componentProps }: ContentBlockProps) => {
  // Correct! JSX type can be a capitalized variable.
  const Block = components[blockType];
  return <Block {...componentProps} />;
};

export default ContentBlock;
