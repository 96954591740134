import React from "react";

const SplitImageBlockImageBlock = ({ imgUrl, imgAltText }) => {
  return (
    <div className="SplitImageBlockImageBlock h-56 bg-indigo-600 sm:h-72 lg:absolute lg:left-0 lg:h-full lg:w-1/2">
      <img
        className="w-full h-full object-cover"
        src={imgUrl}
        alt={imgAltText}
      />
    </div>
  );
};

const SplitImageBlockImageBlockPitchItem = ({ title, value }) => {
  return (
    <div className="SplitImageBlockImageBlockPitchItem flex flex-col px-8 pt-8">
      <dt className="order-2 text-base font-medium text-gray-500">{title}</dt>
      <dd className="order-1 text-2xl font-extrabold text-indigo-600 sm:text-3xl">
        {value}
      </dd>
    </div>
  );
};

interface PitchItem {
  id: string;
  title: string;
  subtitle: string;
  icon: string;
  theme: {
    classNames: string[];
  };
}

interface SplitImageBlockProps {
  theme: {
    classNames: string;
    imageClassNames: string;
    titleClassNames: string;
    descriptionClassNames: string;
    pitchItemTitleClassNames: string;
    pitchItemValueClassNames: string;
  };
  pitchItems: PitchItem[];
}

const SplitImageBlock = ({
  theme: {
    classNames,
    imageClassNames,
    titleClassNames,
    descriptionClassNames,
    pitchItemTitleClassNames,
    pitchItemValueClassNames,
  },
  pitchItems,
}: SplitImageBlockProps) => {
  return (
    <div className="relative bg-white">
      <SplitImageBlockImageBlock
        imgUrl={
          "https://images.unsplash.com/photo-1522071820081-009f0129c71c?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2850&q=80"
        }
      />
      <div className="relative max-w-7xl mx-auto px-4 py-8 sm:py-12 sm:px-6 lg:py-16">
        <div className="max-w-2xl mx-auto lg:max-w-none lg:mr-0 lg:ml-auto lg:w-1/2 lg:pl-10">
          <div>
            <div className="flex items-center justify-center h-12 w-12 rounded-md bg-indigo-500 text-white">
              {/* <UsersIcon className="h-6 w-6" aria-hidden="true" /> */}
              ICON
            </div>
          </div>
          <h2 className="mt-6 text-3xl font-extrabold text-gray-900 sm:text-4xl">
            Deliver what your customers want every time
          </h2>
          <p className="mt-6 text-lg text-gray-500">
            Lorem ipsum dolor, sit amet consectetur adipisicing elit. Dolore
            nihil ea rerum ipsa. Nostrum consectetur sequi culpa doloribus
            omnis, molestiae esse placeat, exercitationem magnam quod molestias
            quia aspernatur deserunt voluptatibus.
          </p>
          <div className="mt-8 overflow-hidden">
            <dl className="-mx-8 -mt-8 flex flex-wrap">
              <SplitImageBlockImageBlockPitchItem
                title={"Delivery"}
                value={"24/7"}
              />
              <SplitImageBlockImageBlockPitchItem
                title={"On Time"}
                value={"99.5%"}
              />
            </dl>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SplitImageBlock;
