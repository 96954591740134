import React from "react";

const LogoBlock = ({ logoUrl, altText }) => {
  return (
    <div className="LogoBlock col-span-1 flex justify-center py-8 px-8 bg-gray-50">
      <img className="max-h-12" src={logoUrl} alt={altText} />
    </div>
  );
};

interface BrandLink {
  id: string;
  to: string;
  logoImg: string;
  name: string;
}

interface LogoCloudProps {
  brands: BrandLink[];
  linkText: string;
  linkDestination: string;
  subtitle: string;
  title: string;
  theme: {
    classNames: string;
    titleClassNames: string;
    subtitleClassNames: string;
    linkClassNames: string;
    logoClassNames: string;
  };
}

const LogoCloud = ({
  brands,
  linkText,
  linkDestination,
  subtitle,
  title,
  theme: {
    classNames,
    titleClassNames,
    subtitleClassNames,
    linkClassNames,
    logoClassNames,
  },
}: LogoCloudProps) => {
  return (
    <div className="LogoCloud mt-32">
      <div className="mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl">
        <div className="lg:grid lg:grid-cols-2 lg:gap-24 lg:items-center">
          <div>
            <h2 className="text-3xl font-extrabold text-gray-900 tracking-tight sm:text-4xl">
              {title}
            </h2>
            <p className="mt-6 max-w-3xl text-lg leading-7 text-gray-500">
              {subtitle}
            </p>
            <div className="mt-6">
              <a href={to} className="text-base font-medium text-rose-500">
                {linkText} &rarr;
              </a>
            </div>
          </div>
          <div className="LogoBlocks__container mt-12 grid grid-cols-2 gap-0.5 md:grid-cols-3 lg:mt-0 lg:grid-cols-2">
            <LogoBlock
              logoUrl={
                "https://tailwindui.com/img/logos/transistor-logo-gray-400.svg"
              }
              altText={"Transistor"}
            />
            <LogoBlock
              logoUrl={
                "https://tailwindui.com/img/logos/mirage-logo-gray-400.svg"
              }
              altText={"Mirage"}
            />
            <LogoBlock
              logoUrl="https://tailwindui.com/img/logos/tuple-logo-gray-400.svg"
              altText="Tuple"
            />
            <LogoBlock
              logoUrl="https://tailwindui.com/img/logos/laravel-logo-gray-400.svg"
              altText="Laravel"
            />
            <LogoBlock
              logoUrl="https://tailwindui.com/img/logos/statickit-logo-gray-400.svg"
              altText="StaticKit"
            />
            <LogoBlock
              logoUrl="https://tailwindui.com/img/logos/workcation-logo-gray-400.svg"
              altText="Workcation"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default LogoCloud;
