import React from "react";
import Grid, { GridConfig } from "./Grid";
import Avatar from "./Avatar";
const ImageBlock = ({ imageUrl, altText }) => {
  return (
    <div className="FeatureImageBlock mt-12 sm:mt-16 lg:mt-0 lg:mx-0 lg:flex lg:flex-col">
      <div className="pl-4 -mr-48 sm:pl-6 md:-mr-16 lg:px-0 lg:m-0 lg:relative lg:h-full lg:flex">
        <img
          className="rounded-xl shadow-xl ring-1 ring-black ring-opacity-5 lg:mt-auto lg:mb-auto lg:flex lg:h-auto lg:my-auto lg:w-full"
          src={imageUrl}
          alt={altText}
        />
      </div>
    </div>
  );
};
const RightContentBlock = () => {
  return (
    <section className="mt-24">
      <div className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24">
        <div className="px-4 max-w-xl mx-auto sm:px-6 lg:py-32 lg:max-w-none lg:mx-0 lg:px-0 lg:col-start-2">
          <div>
            <div>
              <span className="h-12 w-12 rounded-md flex items-center justify-center bg-indigo-600">
                {/* <!-- Heroicon name: outline/sparkles --> */}
                <svg
                  className="h-6 w-6 text-white"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M5 3v4M3 5h4M6 17v4m-2-2h4m5-16l2.286 6.857L21 12l-5.714 2.143L13 21l-2.286-6.857L5 12l5.714-2.143L13 3z"
                  />
                </svg>
              </span>
            </div>
            <div className="mt-6">
              <h2 className="text-3xl font-extrabold tracking-tight text-gray-900">
                Better understand your customers
              </h2>
              <p className="mt-4 text-lg text-gray-500">
                Semper curabitur ullamcorper posuere nunc sed. Ornare iaculis
                bibendum malesuada faucibus lacinia porttitor. Pulvinar laoreet
                sagittis viverra duis. In venenatis sem arcu pretium pharetra
                at. Lectus viverra dui tellus ornare pharetra.
              </p>
              <div className="mt-6">
                <a
                  href="#"
                  className="inline-flex px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700"
                >
                  Get started
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-12 sm:mt-16 lg:mt-0 lg:col-start-1">
          <div className="pr-4 -ml-48 sm:pr-6 md:-ml-16 lg:px-0 lg:m-0 lg:relative lg:h-full">
            <img
              className="w-full rounded-xl shadow-xl ring-1 ring-black ring-opacity-5 lg:absolute lg:right-0 lg:h-full lg:w-auto lg:max-w-none"
              src="https://tailwindui.com/img/component-images/inbox-app-screenshot-2.jpg"
              alt="Customer profile user interface"
            />
          </div>
        </div>
      </div>
    </section>
  );
};

const QuoteBlock = ({ quote, citation }) => {
  return (
    <blockquote>
      <div>
        <p className="text-base text-gray-500">&ldquo;{quote}&rdquo;</p>
      </div>
      <footer className="mt-3">
        <div className="flex items-center space-x-3">
          <Avatar
            imageUrl="https://images.unsplash.com/photo-1509783236416-c9ad59bae472?ixlib=rb-=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=8&w=1024&h=1024&q=80"
            name="Ryan Hurst"
          />
          <div className="text-base font-medium text-gray-700">{citation}</div>
        </div>
      </footer>
    </blockquote>
  );
};

const IconTitleActionBlock = ({
  icon,
  title,
  subtitle,
  actionText,
  actionUrl,
}) => {
  return (
    <div className="IconTitleActionBlock">
      <div>
        <span className="h-12 w-12 rounded-md flex items-center justify-center bg-indigo-600">
          {/* <!-- Heroicon name: outline/inbox --> */}
          <svg
            className={`${icon} h-6 w-6 text-white`}
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            aria-hidden="true"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M20 13V6a2 2 0 00-2-2H6a2 2 0 00-2 2v7m16 0v5a2 2 0 01-2 2H6a2 2 0 01-2-2v-5m16 0h-2.586a1 1 0 00-.707.293l-2.414 2.414a1 1 0 01-.707.293h-3.172a1 1 0 01-.707-.293l-2.414-2.414A1 1 0 006.586 13H4"
            />
          </svg>
          {/* { icon } */}
        </span>
      </div>
      <div className="mt-6">
        <h2 className="text-3xl font-extrabold tracking-tight text-gray-900">
          {title}
        </h2>
        <p className="mt-4 text-lg text-gray-500">{subtitle}</p>
        <div className="mt-6">
          <a
            href={actionUrl}
            className="inline-flex px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700"
          >
            {actionText}
          </a>
        </div>
      </div>
    </div>
  );
};

const FeatureContentBlock = ({
  quote,
  citation,
  icon,
  title,
  subtitle,
  actionText,
  actionUrl,
}) => {
  const shouldShowQuote = !!quote && !!citation;
  return (
    <div className="FeatureContentBlock">
      <IconTitleActionBlock
        icon={icon}
        title={title}
        subtitle={subtitle}
        actionText={actionText}
        actionUrl={actionUrl}
      />

      {shouldShowQuote ? (
        <div className="mt-8 border-t border-gray-200 pt-6">
          <QuoteBlock quote={quote} citation={citation} />
        </div>
      ) : null}
    </div>
  );
};

const gridConfig = {
  sm: [1, 0],
  md: [1, 0],
  lg: [2, 24],
  default: [],
};

interface FeatureBlockProps {
  theme: {
    classNames: string;
    featureContentBlockClassNames: string;
    titleClassNames: string;
    subtitleClassNames: string;
    gridConfig: GridConfig;
  };
  title: string;
  subtitle: string;
  image: string;
}

const FeatureBlock = ({
  theme: {
    classNames,
    featureContentBlockClassNames,
    titleClassNames,
    subtitleClassNames,
    gridConfig,
  },
  title,
  subtitle,
  image,
}: FeatureBlockProps) => {
  return (
    <section className="FeatureBlock">
      <Grid
        className={"lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid-flow-col-dense "}
        grid={gridConfig}
      >
        <FeatureContentBlock />
        <ImageBlock
          imageUrl={
            "https://tailwindui.com/img/component-images/inbox-app-screenshot-1.jpg"
          }
          altText={"App screenshot"}
        />
      </Grid>
    </section>
  );
};

export default FeatureBlock;
