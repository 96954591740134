import React from "react";

interface ProductCardProps {
  theme: {
    imageClassNames: string;
    titleClassNames: string;
    priceClassNames: string;
  };
  to: string;
  title: string;
  price: string;
  imgSrc: string;
}

const ProductCard = ({
  to,
  title,
  price,
  imgSrc,
  theme: { imageClassNames, titleClassNames, priceClassNames },
}: ProductCardProps) => {
  return (
    <a href={to} className="group">
      <div
        className={`${imageClassNames} w-full aspect-w-1 aspect-h-1  overflow-hidden xl:aspect-w-7 xl:aspect-h-8`}
      >
        <img
          src={imgSrc}
          alt={title}
          className="w-full h-full object-center object-cover group-hover:opacity-75"
        />
      </div>
      <h3 className={`${titleClassNames}`}>{title}</h3>
      <p className={`${priceClassNames}`}>{price}</p>
    </a>
  );
};

export default ProductCard;
