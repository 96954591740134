import React from "react";

export interface GridConfig {
  sm?: string[];
  md?: string[];
  lg?: string[];
}

interface GridProps {
  grid: GridConfig;
  children?: React.ReactNode;
  theme: {
    classNames?: string;
  };
}

function generateGridClasses(key: string, config: GridConfig) {
  const gridArray = config[key];
  if (gridArray && gridArray.length === 2) {
    let [col, gap] = gridArray;
    return `${key}:grid ${key}:grid-cols-${col} ${key}:gap-${gap}`;
  }

  return "";
}

function deriveResponsiveGrid(gridConfig: GridConfig) {
  return `${generateGridClasses("sm", gridConfig)} ${generateGridClasses(
    "md",
    gridConfig
  )} ${generateGridClasses("lg", gridConfig)}`;
}

const Grid = ({ grid, children, theme }: GridProps) => {
  var classNames = "";
  if (theme && theme.classNames) {
    classNames = theme.classNames;
  }

  return (
    <div
      className={`Grid ${deriveResponsiveGrid(grid)} ${
        !!classNames ? classNames : ""
      }`}
    >
      {children}
    </div>
  );
};

export default Grid;
