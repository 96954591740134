import React from "react";

interface HeaderProps {
  title?: string;
  subtitle?: string;
  lead?: string;
  theme: {
    classNames: string;
    leadClassNames: string;
    titleClassNames: string;
    subtitleClassNames: string;
  };
}

const Header = ({
  title,
  subtitle,
  lead,
  theme: { classNames, leadClassNames, titleClassNames, subtitleClassNames },
}: HeaderProps) => {
  return (
    <div className={`PageHeader ${classNames}`}>
      <div className="PageHeader__container">
        <h2 className={`${leadClassNames}`}>{lead}</h2>
        <p className={`${titleClassNames} `}>{title}</p>
        <p className={`${subtitleClassNames} `}>{subtitle}</p>
      </div>
    </div>
  );
};

export default Header;
