import { request } from "http";
import React from "react";
import { useEffect } from "react";
import Grid, { GridConfig } from "./Grid";

//   const gridConfig = {
//     xs: [1, 10],
//     sm: [2, 10],
//     md: [2, 10],
//     lg: [3, 5],
//     default: [],
// }

interface CardProps {
  children: React.ReactNode;
  theme: {
    classNames?: string;
  };
}

const Card = ({ children, theme: { classNames } }: CardProps) => {
  return <div className={`Card ${classNames}`}>{children}</div>;
};

interface TitleRowProps {
  theme: {
    categoryClassNames: string;
    titleClassNames: string;
    subtitleClassNames: string;
  };
  categoryName: string;
  categoryLink: string;
  title: string;
  subtitle: string;
  postLink: string;
}

const TitleRow = ({
  theme: { categoryClassNames, titleClassNames, subtitleClassNames },
  categoryName,
  categoryLink,
  title,
  subtitle,
  postLink,
}: TitleRowProps) => {
  return (
    <div className="flex-1">
      <p className={`Card__TitleRow ${categoryClassNames}`}>
        <a href={categoryLink} className="hover:underline">
          {categoryName}
        </a>
      </p>
      <a href={postLink} className="block mt-2">
        <p className={titleClassNames}>{title}</p>
        <p className={subtitleClassNames}>{subtitle}</p>
      </a>
    </div>
  );
};

interface AuthorBlockProps {
  authorLink: string;
  authorName: string;
  authorAvatar: string;
  readingTime: string;
  date: string;
  time: string;
}

const AuthorBlock = ({
  authorLink,
  authorName,
  authorAvatar,
  readingTime,
  date,
  time,
}: AuthorBlockProps) => {
  return (
    <div className="AuthorBlock mt-6 flex items-center">
      <div className="flex-shrink-0">
        <a href={authorLink}>
          <span className="sr-only">{authorName}</span>
          <img className="h-10 w-10 rounded-full" src={authorAvatar} alt="" />
        </a>
      </div>
      <div className=" ml-3">
        <p className="text-sm font-medium text-gray-900">
          <a href={authorLink} className="hover:underline">
            {authorName}
          </a>
        </p>
        <div className="flex space-x-1 text-sm text-gray-500">
          <time dateTime={time}>{date}</time>
          <span aria-hidden="true">&middot;</span>
          <span>{readingTime} read</span>
        </div>
      </div>
    </div>
  );
};

interface ArticleCardProps {
  theme: {
    cardClassNames: string;
    cardCategoryClassNames: string;
    cardTitleClassNames: string;
    cardSubtitleClassNames: string;
  };
  post: any;
}

const ArticleCard = ({
  post,
  theme: {
    cardClassNames,
    cardCategoryClassNames,
    cardTitleClassNames,
    cardSubtitleClassNames,
  },
}: ArticleCardProps) => {
  return (
    <Card theme={{ classNames: cardClassNames }}>
      <div className="Card__ImageContainer">
        <img src={post.imageUrl} alt={post.title} />
      </div>
      <div className="flex-1 p-6 flex flex-col justify-between">
        {/* Title Block  */}
        <TitleRow
          categoryName={post.category.name}
          to={post.category.href}
          title={post.title}
          subtitle={post.description}
          categoryLink={post.category.href}
          postLink={post.href}
          theme={{
            categoryClassNames: cardCategoryClassNames,
            titleClassNames: cardTitleClassNames,
            subtitleClassNames: cardSubtitleClassNames,
          }}
        />
        {/* Author Block */}
        {/* <AuthorBlock 
                  authorLink={post.author.href} 
                  authorName={post.author.name} 
                  authorAvatar={post.author.imageUrl} 
                  readingTime={post.readingTime} 
                  date={post.date}
                  time={post.datetime} /> */}
      </div>
    </Card>
  );
};

interface BasicTitleBlockProps {
  theme: {
    titleClassNames: string;
    subtitleClassNames: string;
  };
  title: string;
  subtitle?: string;
}

const BasicTitleBlock = ({
  title,
  subtitle,
  theme: { titleClassNames, subtitleClassNames },
}: BasicTitleBlockProps) => {
  return (
    <div className="w-full">
      <h2 className={titleClassNames}>{title}</h2>
      <p className={subtitleClassNames}>{subtitle}</p>
    </div>
  );
};

interface TitleCardBlockProps {
  theme: {
    cardClassNames: string;
    cardSubtitleClassNames: string;
    cardTitleClassNames: string;
    cardCategoryClassNames: string;
    classNames: string;
    gridConfig: GridConfig;
    subtitleClassNames: string;
    titleClassNames: string;
  };
  cards: any[];
  subtitle?: string;
  title?: string;
  requestConfig?: RequestConfig;
}

interface RequestConfig {
  uri: string;
  method: string;
  body: any;
  headers: any;
}

const TitleCardBlock = ({
  theme: {
    cardClassNames,
    cardSubtitleClassNames,
    cardTitleClassNames,
    cardCategoryClassNames,
    classNames,
    gridConfig,
    subtitleClassNames,
    titleClassNames,
  },
  cards,
  subtitle,
  title,
}: // requestConfig,
TitleCardBlockProps) => {
  // useEffect(() => {
  //   if (!!requestConfig && requestConfig.uri !== undefined) {
  //     fetch(requestConfig?.uri, {
  //       method: requestConfig?.method,
  //       headers: requestConfig?.headers,
  //       body: requestConfig?.body,
  //     });
  //   }
  //   console.log("Some arbitrary function call");
  // }, []);
  return (
    <section
      className={`relative py-8 pt-12 px-4 sm:px-6 lg:pt-24 lg:pb-28 lg:px-8 ${classNames}`}
    >
      <div className="relative max-w-7xl mx-auto">
        <BasicTitleBlock
          title={title}
          subtitle={subtitle}
          theme={{
            titleClassNames: titleClassNames,
            subtitleClassNames: subtitleClassNames,
          }}
        />
        <Grid
          grid={gridConfig}
          className={"CardContainer mt-12 mx-auto lg:max-w-none"}
        >
          {cards &&
            cards.map((post) => (
              <ArticleCard
                post={post}
                key={post.title}
                theme={{
                  cardClassNames: cardClassNames,
                  cardTitleClassNames: cardTitleClassNames,
                  cardSubtitleClassNames: cardSubtitleClassNames,
                  cardCategoryClassNames: cardCategoryClassNames,
                }}
              />
            ))}
        </Grid>
      </div>
    </section>
  );
};

export default TitleCardBlock;
