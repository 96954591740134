import React from "react";
import ContentBlock from "./ContentBlock";

interface SurfaceProps {
  theme: {
    classNames: string;
    containerClassNames: string;
  };
  surfaceLink?: string;
  surfaceLinkName?: string;
  blocks: any[];
  bgImg: string;
}

const Surface = ({
  surfaceLink,
  surfaceLinkName,
  blocks,
  theme: { classNames, containerClassNames },
}: SurfaceProps): JSX.Element => {
  return (
    <section className={`Surface ${classNames}`}>
      <div className={`Surface__container ${containerClassNames}`}>
        {blocks.map((b, i) => {
          return (
            <ContentBlock
              key={`${i}_${b.type}`}
              blockType={b.type}
              componentProps={b.props}
            />
          );
        })}
      </div>
      {surfaceLink && surfaceLink !== "" ? (
        <a className="surface-link" href={surfaceLink}>
          <span className="sr-only">{surfaceLinkName}</span>
        </a>
      ) : null}
    </section>
  );
};

export default Surface;
