import React, { useEffect, useState } from "react";
import { Link } from "gatsby";
import Grid, { GridConfig } from "./Grid";
import { useStaticQuery, graphql } from "gatsby";
import Icon from "./Icon";

const isBrowser = typeof window !== "undefined";

const footerThemeConfiguration = {
  bgColor: "bg-gray-900",
  linkColor: "",
  textColor: "",
};

interface FooterLinkProps {
  text: string;
  to: string;
  theme: {
    classNames: string;
  };
}

const FooterLink = ({ text, to, theme: { classNames } }: FooterLinkProps) => {
  return (
    <a href={to} className={`FooterLink ${classNames}`}>
      {text}
    </a>
  );
};

interface LinkObj {
  id?: string;
  to: string;
  linkText?: string;
  newWindow?: boolean;
  icon?: string;
  childComponents: any[];
  theme: {
    classNames: string;
  };
}

interface FooterLinkBlockProps {
  title: string;
  description?: string;
  links: LinkObj[];
  theme: {
    blockClassNames: string;
    linkClassNames: string;
    titleClassNames: string;
    descriptionClassNames: string;
  };
}

const FooterLinkBlock = ({
  title,
  description,
  links,
  theme: {
    blockClassNames,
    linkClassNames,
    titleClassNames,
    descriptionClassNames,
  },
}: FooterLinkBlockProps) => {
  return (
    <nav className={`flex flex-wrap justify-center ${blockClassNames}`}>
      {title && title !== "" ? (
        <h3 className={titleClassNames}>{title}</h3>
      ) : null}
      {description && description !== "" ? (
        <p className={descriptionClassNames}>{description}</p>
      ) : null}

      {links.length > 0 &&
        links.map((l, i) => {
          return (
            <Link
              key={`i-${l.id}`}
              to={l.to}
              className={`NavbarSimpleLink ${linkClassNames} ${l.theme?.classNames}`}
              activeClassName="border-indigo-500 dark:border-yellow-500"
            >
              {l.linkText}
            </Link>
          );
        })}
    </nav>
  );
};

const getDarkmodeState = () => {
  var isDarkMode = false;
  if (isBrowser) {
    if (
      localStorage.mode === "dark" ||
      (!("mode" in localStorage) &&
        window.matchMedia("(prefers-color-scheme: dark)").matches)
    ) {
      document.documentElement.classList.add("dark");
      localStorage.mode = "dark";
      isDarkMode = true;
    } else {
      document.documentElement.classList.remove("dark");
      localStorage.mode = "light";
      isDarkMode = false;
    }
  }
  return isDarkMode;
};

const DarkModeToggle = () => {
  const initialDarkMode = getDarkmodeState();
  const [isDarkMode, setIsDarkMode] = useState(initialDarkMode);

  const handleSetDarkMode = (mode) => {
    if (mode === "dark") {
      localStorage.mode = "dark";
      setIsDarkMode(true);
      document.documentElement.classList.add("dark");
    }

    if (mode === "light") {
      localStorage.mode = "light";
      document.documentElement.classList.remove("dark");
      setIsDarkMode(false);
    }
  };

  useEffect(() => {
    const mountedDarkModeState = getDarkmodeState();
    setIsDarkMode(mountedDarkModeState);
  }, []);

  return (
    <div className="DarkModeToggleRow" className="flex flex-row w-full">
      {isDarkMode ? (
        <button
          onClick={() => {
            handleSetDarkMode("light");
          }}
          className="ml-auto p-4"
        >
          <Icon variation="solid" name="sun" />
        </button>
      ) : (
        <button
          onClick={() => {
            handleSetDarkMode("dark");
          }}
          className="ml-auto p-4"
        >
          <Icon variation="solid" name="moon" />
        </button>
      )}
    </div>
  );
};

interface FooterSocialIcon {
  iconName: string;
  uri: string;
}

interface SocialIconsRowProps {
  socialIcons: FooterSocialIcon[];
  theme: {
    classNames: string;
    iconClassNames: string;
  };
}

const SocialIconsRow = ({ socialIcons, theme }: SocialIconsRowProps) => {
  return (
    <>
      {socialIcons.map((si, i) => {
        return (
          <a href={si.uri} target="_BLANK" className={theme?.classNames}>
            <span className="sr-only">{si.iconName}</span>
            <Icon
              name={si.iconName}
              variation="logo"
              classNames={`fill-current ${theme?.iconClassNames}`}
            />
          </a>
        );
      })}
    </>
  );
};

const Footer = ({ config }) => {
  const { copyrightText, theme, linkBlocks, legalLink, socialIcons } = config;
  const { name, navLink, socialRow, legalText, footerClassNames } = theme;
  console.log({ theme });
  return (
    <footer className={`Footer ${name} ${footerClassNames}`}>
      <div className="mx-auto max-w-md py-12 px-4 overflow-hidden sm:max-w-3xl sm:px-6 lg:max-w-7xl lg:px-8">
        {linkBlocks.map((lb, i) => {
          return (
            <FooterLinkBlock
              key={`FooterLinkBlock__${i}`}
              {...lb}
              theme={{
                classNames: lb.theme?.blockClasses,
                linkClassNames: `${navLink?.classNames} ${lb.theme?.linkClassNames}`,
              }}
            />
          );
        })}
        <div className="mt-8 flex justify-center space-x-6">
          <SocialIconsRow
            theme={{
              classNames: socialRow.classNames,
              iconClassNames: socialRow.iconClassNames,
            }}
            socialIcons={socialIcons}
          />
        </div>
        {legalText?.shouldShowLegalText ? (
          <p className={legalText.classNames}>&copy; {copyrightText}</p>
        ) : null}
      </div>
      <div>
        <DarkModeToggle />
      </div>
    </footer>
  );
};

export default Footer;

const query = graphql`
  query FooterData {
    site {
      siteMetadata {
        socialAccounts {
          platform
          priority
          showInNav
          userName
          webUri
        }
      }
    }
  }
`;
