import React from "react";

const OffsetFeatureListItem = ({
  icon,
  title,
  subtitle,
  theme: { classNames, iconClassNames },
}) => {
  return (
    <div className="relative">
      <dt>
        {/* <!-- Heroicon name: outline/check --> */}
        <svg
          className={`${icon} absolute h-6 w-6 text-green-500`}
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          aria-hidden="true"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M5 13l4 4L19 7"
          />
        </svg>
        <p className="ml-9 text-lg leading-6 font-medium text-gray-900">
          {title}
        </p>
      </dt>
      <dd className="mt-2 ml-9 text-base text-gray-500">{subtitle}</dd>
    </div>
  );
};

function genFeature(title, subtitle) {
  return {
    icon: "check",
    title: title,
    subtitle: subtitle,
    theme: {
      className: "",
      titleClassNames: "",
      subtitleClassNames: "",
      iconClassNames: "",
    },
  };
}

const featureList = [
  genFeature(
    "List View",
    "You can manage phone, email and chat conversations all from a single mailbox."
  ),
  genFeature(
    "Boards",
    "Find what you need with advanced filters, bulk actions, and quick views."
  ),
  genFeature(
    "Keyboard Shortcuts",
    "You can manage phone, email and chat conversations all from a single mailbox."
  ),
  genFeature(
    "Reporting",
    "Find what you need with advanced filters, bulk actions, and quick views."
  ),
  genFeature(
    "Calendars",
    "You can manage phone, email and chat conversations all from a single mailbox."
  ),
  genFeature(
    "Mobile App",
    "Find what you need with advanced filters, bulk actions, and quick views."
  ),
  genFeature(
    "Notifications",
    "Find what you need with advanced filters, bulk actions, and quick views."
  ),
];

interface FeatureItem {
  id: string;
  title: string;
  subtitle: string;
  icon: string;
  theme: {
    classNames: string;
    titleClassNames: string;
    subtitleClassNames: string;
    iconClassNames: string;
  };
}

interface OffsetFeatureListBlockProps {
  theme: {
    classNames: string;
    titleClassNames: string;
    subtitleClassNames: string;
    leadClassNames: string;
    iconClassNames: string;
    listItemClassNames: string;
  };
  title: string;
  subtitle: string;
  lead: string;
  features: FeatureItem[];
}

const OffsetFeatureListBlock = ({
  title,
  subtitle,
  lead,
  theme: {
    classNames,
    titleClassNames,
    subtitleClassNames,
    iconClassNames,
    leadClassNames,
    listItemClassNames,
  },
}: OffsetFeatureListBlockProps) => {
  // default lead class names: 'text-base font-semibold text-indigo-600 uppercase tracking-wide'
  // Defauilt title class names: 'mt-2 text-3xl font-extrabold text-gray-900'
  return (
    <section className={`OffsetFeatureListBlock ${classNames}`}>
      <div>
        <h2 className={`FeatureListBlockLead ${leadClassNames}`}>{lead}</h2>
        <p className={`FeatureListBlockTitle ${titleClassNames}`}>{title}</p>
        <p className={`FeatureListBlockSubitle ${subtitleClassNames}`}>
          {subtitle}
        </p>
      </div>
      <div className="mt-12 lg:mt-0 lg:col-span-2">
        <dl className="space-y-10 sm:space-y-0 sm:grid sm:grid-cols-2 sm:grid-rows-4 sm:grid-flow-col sm:gap-x-6 sm:gap-y-10 lg:gap-x-8">
          {featureList.map((fl: FeatureItem, i: number) => {
            return (
              <OffsetFeatureListItem
                key={`${fl.title} ${i}`}
                title={fl.title}
                subtitle={fl.subtitle}
                icon={fl.icon}
                theme={{
                  classNames: listItemClassNames,
                  iconClassNames: `${iconClassNames} ${fl.theme?.iconClassNames}`,
                }}
              />
            );
          })}
        </dl>
      </div>
    </section>
  );
};

export default OffsetFeatureListBlock;
