import React, { Fragment } from "react";

import {
  SolidAcademicCap,
  SolidAdjustments,
  SolidAnnotation,
  SolidArchive,
  SolidArrowCircleDown,
  SolidArrowCircleLeft,
  SolidArrowCircleRight,
  SolidArrowCircleUp,
  SolidArrowDown,
  SolidArrowLeft,
  SolidArrowNarrowDown,
  SolidArrowNarrowLeft,
  SolidArrowNarrowRight,
  SolidArrowNarrowUp,
  SolidArrowRight,
  SolidArrowSmDown,
  SolidArrowSmLeft,
  SolidArrowSmRight,
  SolidArrowSmUp,
  SolidArrowUp,
  SolidArrowsExpand,
  SolidAtSymbol,
  SolidBackspace,
  SolidBadgeCheck,
  SolidBan,
  SolidBeaker,
  SolidBell,
  SolidBookOpen,
  SolidBookmarkAlt,
  SolidBookmark,
  SolidBriefcase,
  SolidCake,
  SolidCalculator,
  SolidCalendar,
  SolidCamera,
  SolidCash,
  SolidChatBar,
  SolidChartPie,
  SolidChartSqareBar,
  SolidCatAlt2,
  SolidChatAlt,
  SolidChat,
  SolidCheckCircle,
  SolidCheck,
  SolidChevronDoubleDown,
  SolidChevronDoubleLeft,
  SolidChevronDoubleRight,
  SolidChevronDoubleUp,
  SolidChveronDown,
  SolidChveronLeft,
  SolidChveronRight,
  SolidChveronUp,
  SolidChip,
  SolidClipboardCheck,
  SolidClipboardCopy,
  SolidClipboardList,
  SolidClipboard,
  SolidClock,
  SolidCloudDownload,
  SolidCloudUpload,
  SolidCloud,
  SolidCode,
  SolidCog,
  SolidCollection,
  SolidColorSwatch,
  SolidCreditCard,
  SolidCubeTransparent,
  SolidCube,
  SolidCurrencyBangladesh,
  SolidCurrencyDollar,
  SolidCurrencyEuro,
  SolidCurrencyPound,
  SolidCurrencyRupee,
  SolidCurrencyYen,
  SolidCursorClick,
  SolidDatabase,
  SolidDesktopComputer,
  SolidDeviceMobile,
  SolidDeviceTablet,
  SolidDocumentAdd,
  SolidDocumentDownload,
  SolidDocumentDuplicate,
  SolidDocumentRemove,
  SolidDocumentReport,
  SolidDocumentSearch,
  SolidDocumentText,
  SolidDocument,
  SolidDotsCircleHorizontal,
  SolidDotsHorizontal,
  SolidDotsVertical,
  SolidDownload,
  SolidDuplicate,
  SolidEmojiHappy,
  SolidEmojiSad,
  SolidExclamationCircle,
  SolidExclamation,
  SolidExternalLink,
  SolidEyeOff,
  SolidEye,
  SolidFastForward,
  SolidFilm,
  SolidFilter,
  SolidFingerPrint,
  SolidFire,
  SolidFlag,
  SolidFolderAdd,
  SolidFolderDownload,
  SolidFolderOpen,
  SolidFolderRemove,
  SolidFolder,
  SolidGift,
  SolidGlobeAlt,
  SolidGlobe,
  SolidHand,
  SolidHashtag,
  SolidHeart,
  SolidHome,
  SolidIdentification,
  SolidInboxIn,
  SolidInbox,
  SolidInformationCircle,
  SolidKey,
  SolidLibrary,
  SolidLightBulb,
  SolidLightningBolt,
  SolidLink,
  SolidLocationMarker,
  SolidLockClosed,
  SolidLockOpen,
  SolidLogin,
  SolidLogout,
  SolidMailOpen,
  SolidMail,
  SolidMap,
  SolidMenuAlt1,
  SolidMenuAlt2,
  SolidMenuAlt3,
  SolidMenuAlt4,
  SolidMenu,
  SolidMicrophone,
  SolidMinusCircle,
  SolidMinusSm,
  SolidMinus,
  SolidMoon,
  SolidMusicNote,
  SolidNewspaper,
  SolidOfficeBuilding,
  SolidPaperAirplane,
  SolidPaperClip,
  SolidPause,
  SolidPencilAlt,
  SolidPencil,
  SolidPhoneIncoming,
  SolidPhoneMissedCall,
  SolidPhoneOutgoing,
  SolidPhone,
  SolidPhotograph,
  SolidPlay,
  SolidPlusCircle,
  SolidPlusSm,
  SolidPlus,
  SolidPresentationChartBar,
  SolidPresentationChartLine,
  SolidPrinter,
  SolidPuzzle,
  SolidQRCode,
  SolidQuestionMarkCircle,
  SolidReceiptRefund,
  SolidReceiptTax,
  SolidRefresh,
  SolidReply,
  SolidRewind,
  SolidRss,
  SolidSaveAs,
  SolidSave,
  SolidScale,
  SolidScissors,
  SolidSearchCircle,
  SolidSearch,
  SolidSelector,
  SolidServer,
  SolidShare,
  SolidShieldCheck,
  SolidShieldExclamation,
  SolidShoppingBag,
  SolidShoppingCart,
  SolidSortAscending,
  SolidSortDescending,
  SolidSparkles,
  SolidSpeakerphone,
  SolidStar,
  SolidStatusOffline,
  SolidStatusOnline,
  SolidStop,
  SolidSun,
  SolidSupport,
  SolidSwitchHorizontal,
  SolidSwitchVertical,
  SolidTable,
  SolidTag,
  SolidTemplate,
  SolidTerminal,
  SolidThumbDown,
  SolidThumbUp,
  SolidTicket,
  SolidTranslate,
  SolidTrash,
  SolidTrendingDown,
  SolidTrendingUp,
  SolidTruck,
  SolidUpload,
  SolidUserAdd,
  SolidUserCircle,
  SolidUserGroup,
  SolidUserRemove,
  SolidUser,
  SolidUsers,
  SolidVariable,
  SolidVideoCamera,
  SolidViewBoards,
  SolidViewGridAdd,
  SolidViewGrid,
  SolidViewList,
  SolidVolumeOff,
  SolidVolumeUp,
  SolidWifi,
  SolidXCircle,
  SolidX,
  SolidZoomIn,
  SolidZoomOut,
  OutlineAcademicCap,
  OutlineAdjustments,
  OutlineAnnotation,
  OutlineArchive,
  OutlineArrowCircleDown,
  OutlineArrowCircleLeft,
  OutlineArrowCircleRight,
  OutlineArrowCircleUp,
  OutlineArrowDown,
  OutlineArrowLeft,
  OutlineArrowNarrowDown,
  OutlineArrowNarrowLeft,
  OutlineArrowNarrowRight,
  OutlineArrowNarrowUp,
  OutlineArrowRight,
  OutlineArrowSmDown,
  OutlineArrowSmLeft,
  OutlineArrowSmRight,
  OutlineArrowSmUp,
  OutlineArrowUp,
  OutlineArrowsExpand,
  OutlineAtSymbol,
  OutlineBackspace,
  OutlineBadgeCheck,
  OutlineBan,
  OutlineBeaker,
  OutlineBell,
  OutlineBookOpen,
  OutlineBookmarkAlt,
  OutlineBookmark,
  OutlineBriefcase,
  OutlineCake,
  OutlineCalculator,
  OutlineCalendar,
  OutlineCamera,
  OutlineCash,
  OutlineChatBar,
  OutlineChartPie,
  OutlineChartSqareBar,
  OutlineCatAlt2,
  OutlineChatAlt,
  OutlineChat,
  OutlineCheckCircle,
  OutlineCheck,
  OutlineChevronDoubleDown,
  OutlineChevronDoubleLeft,
  OutlineChevronDoubleRight,
  OutlineChevronDoubleUp,
  OutlineChveronDown,
  OutlineChveronLeft,
  OutlineChveronRight,
  OutlineChveronUp,
  OutlineChip,
  OutlineClipboardCheck,
  OutlineClipboardCopy,
  OutlineClipboardList,
  OutlineClipboard,
  OutlineClock,
  OutlineCloudDownload,
  OutlineCloudUpload,
  OutlineCloud,
  OutlineCode,
  OutlineCog,
  OutlineCollection,
  OutlineColorSwatch,
  OutlineCreditCard,
  OutlineCubeTransparent,
  OutlineCube,
  OutlineCurrencyBangladesh,
  OutlineCurrencyDollar,
  OutlineCurrencyEuro,
  OutlineCurrencyPound,
  OutlineCurrencyRupee,
  OutlineCurrencyYen,
  OutlineCursorClick,
  OutlineDatabase,
  OutlineDesktopComputer,
  OutlineDeviceMobile,
  OutlineDeviceTablet,
  OutlineDocumentAdd,
  OutlineDocumentDownload,
  OutlineDocumentDuplicate,
  OutlineDocumentRemove,
  OutlineDocumentReport,
  OutlineDocumentSearch,
  OutlineDocumentText,
  OutlineDocument,
  OutlineDotsCircleHorizontal,
  OutlineDotsHorizontal,
  OutlineDotsVertical,
  OutlineDownload,
  OutlineDuplicate,
  OutlineEmojiHappy,
  OutlineEmojiSad,
  OutlineExclamationCircle,
  OutlineExclamation,
  OutlineExternalLink,
  OutlineEyeOff,
  OutlineEye,
  OutlineFastForward,
  OutlineFilm,
  OutlineFilter,
  OutlineFingerPrint,
  OutlineFire,
  OutlineFlag,
  OutlineFolderAdd,
  OutlineFolderDownload,
  OutlineFolderOpen,
  OutlineFolderRemove,
  OutlineFolder,
  OutlineGift,
  OutlineGlobeAlt,
  OutlineGlobe,
  OutlineHand,
  OutlineHashtag,
  OutlineHeart,
  OutlineHome,
  OutlineIdentification,
  OutlineInboxIn,
  OutlineInbox,
  OutlineInformationCircle,
  OutlineKey,
  OutlineLibrary,
  OutlineLightBulb,
  OutlineLightningBolt,
  OutlineLink,
  OutlineLocationMarker,
  OutlineLockClosed,
  OutlineLockOpen,
  OutlineLogin,
  OutlineLogout,
  OutlineMailOpen,
  OutlineMail,
  OutlineMap,
  OutlineMenuAlt1,
  OutlineMenuAlt2,
  OutlineMenuAlt3,
  OutlineMenuAlt4,
  OutlineMenu,
  OutlineMicrophone,
  OutlineMinusCircle,
  OutlineMinusSm,
  OutlineMinus,
  OutlineMoon,
  OutlineMusicNote,
  OutlineNewspaper,
  OutlineOfficeBuilding,
  OutlinePaperAirplane,
  OutlinePaperClip,
  OutlinePause,
  OutlinePencilAlt,
  OutlinePencil,
  OutlinePhoneIncoming,
  OutlinePhoneMissedCall,
  OutlinePhoneOutgoing,
  OutlinePhone,
  OutlinePhotograph,
  OutlinePlay,
  OutlinePlusCircle,
  OutlinePlusSm,
  OutlinePlus,
  OutlinePresentationChartBar,
  OutlinePresentationChartLine,
  OutlinePrinter,
  OutlinePuzzle,
  OutlineQRCode,
  OutlineQuestionMarkCircle,
  OutlineReceiptRefund,
  OutlineReceiptTax,
  OutlineRefresh,
  OutlineReply,
  OutlineRewind,
  OutlineRss,
  OutlineSaveAs,
  OutlineSave,
  OutlineScale,
  OutlineScissors,
  OutlineSearchCircle,
  OutlineSearch,
  OutlineSelector,
  OutlineServer,
  OutlineShare,
  OutlineShieldCheck,
  OutlineShieldExclamation,
  OutlineShoppingBag,
  OutlineShoppingCart,
  OutlineSortAscending,
  OutlineSortDescending,
  OutlineSparkles,
  OutlineSpeakerphone,
  OutlineStar,
  OutlineStatusOffline,
  OutlineStatusOnline,
  OutlineStop,
  OutlineSun,
  OutlineSupport,
  OutlineSwitchHorizontal,
  OutlineSwitchVertical,
  OutlineTable,
  OutlineTag,
  OutlineTemplate,
  OutlineTerminal,
  OutlineThumbDown,
  OutlineThumbUp,
  OutlineTicket,
  OutlineTranslate,
  OutlineTrash,
  OutlineTrendingDown,
  OutlineTrendingUp,
  OutlineTruck,
  OutlineUpload,
  OutlineUserAdd,
  OutlineUserCircle,
  OutlineUserGroup,
  OutlineUserRemove,
  OutlineUser,
  OutlineUsers,
  OutlineVariable,
  OutlineVideoCamera,
  OutlineViewBoards,
  OutlineViewGridAdd,
  OutlineViewGrid,
  OutlineViewList,
  OutlineVolumeOff,
  OutlineVolumeUp,
  OutlineWifi,
  OutlineXCircle,
  OutlineX,
  OutlineZoomIn,
  OutlineZoomOut,
  LogoFacebook,
  LogoInstagram,
  LogoSnapchat,
  LogoTikTok,
  LogoTwitter,
  SiteLogo,
} from "../icons";

const iconComponents = {
  outline_academic_cap: OutlineAcademicCap,
  outline_adjustments: OutlineAdjustments,
  outline_annotation: OutlineAnnotation,
  outline_archive: OutlineArchive,
  outline_arrow_circle_down: OutlineArrowCircleDown,
  outline_arrow_circle_left: OutlineArrowCircleLeft,
  outline_arrow_circle_right: OutlineArrowCircleRight,
  outline_arrow_circle_up: OutlineArrowCircleUp,
  outline_arrow_down: OutlineArrowDown,
  outline_arrow_left: OutlineArrowLeft,
  outline_arrow_narrow_down: OutlineArrowNarrowDown,
  outline_arrow_narrow_left: OutlineArrowNarrowLeft,
  outline_arrow_narrow_right: OutlineArrowNarrowRight,
  outline_arrow_narrow_up: OutlineArrowNarrowUp,
  outline_arrow_right: OutlineArrowRight,
  outline_arrow_sm_down: OutlineArrowSmDown,
  outline_arrow_sm_left: OutlineArrowSmLeft,
  outline_arrow_sm_right: OutlineArrowSmRight,
  outline_arrow_sm_up: OutlineArrowSmUp,
  outline_arrow_up: OutlineArrowUp,
  outline_arrows_expand: OutlineArrowsExpand,
  outline_at_symbol: OutlineAtSymbol,
  outline_backspace: OutlineBackspace,
  outline_badge_check: OutlineBadgeCheck,
  outline_ban: OutlineBan,
  outline_beaker: OutlineBeaker,
  outline_bell: OutlineBell,
  outline_book_open: OutlineBookOpen,
  outline_bookmark_alt: OutlineBookmarkAlt,
  outline_bookmark: OutlineBookmark,
  outline_briefcase: OutlineBriefcase,
  outline_cake: OutlineCake,
  outline_calculator: OutlineCalculator,
  outline_calendar: OutlineCalendar,
  outline_camera: OutlineCamera,
  outline_cash: OutlineCash,
  outline_chart_bar: OutlineChatBar,
  outline_chart_pie: OutlineChartPie,
  outline_chart_square_bar: OutlineChartSqareBar,
  outline_chat_alt_2: OutlineCatAlt2,
  outline_chat_alt: OutlineChatAlt,
  outline_chat: OutlineChat,
  outline_check_circle: OutlineCheckCircle,
  outline_check: OutlineCheck,
  outline_chevron_double_down: OutlineChevronDoubleDown,
  outline_chevron_double_left: OutlineChevronDoubleLeft,
  outline_chevron_double_right: OutlineChevronDoubleRight,
  outline_chevron_double_up: OutlineChevronDoubleUp,
  outline_chevron_down: OutlineChveronDown,
  outline_chevron_left: OutlineChveronLeft,
  outline_chevron_right: OutlineChveronRight,
  outline_chevron_up: OutlineChveronUp,
  outline_chip: OutlineChip,
  outline_clipboard_check: OutlineClipboardCheck,
  outline_clipboard_copy: OutlineClipboardCopy,
  outline_clipboard_list: OutlineClipboardList,
  outline_clipboard: OutlineClipboard,
  outline_clock: OutlineClock,
  outline_cloud_download: OutlineCloudDownload,
  outline_cloud_upload: OutlineCloudUpload,
  outline_cloud: OutlineCloud,
  outline_code: OutlineCode,
  outline_cog: OutlineCog,
  outline_collection: OutlineCollection,
  outline_color_swatch: OutlineColorSwatch,
  outline_credit_card: OutlineCreditCard,
  outline_cube_transparent: OutlineCubeTransparent,
  outline_cube: OutlineCube,
  outline_currency_bangladeshi: OutlineCurrencyBangladesh,
  outline_currency_dollar: OutlineCurrencyDollar,
  outline_currency_euro: OutlineCurrencyEuro,
  outline_currency_pound: OutlineCurrencyPound,
  outline_currency_rupee: OutlineCurrencyRupee,
  outline_currency_yen: OutlineCurrencyYen,
  outline_cursor_click: OutlineCursorClick,
  outline_database: OutlineDatabase,
  outline_desktop_computer: OutlineDesktopComputer,
  outline_device_mobile: OutlineDeviceMobile,
  outline_device_tablet: OutlineDeviceTablet,
  outline_document_add: OutlineDocumentAdd,
  outline_document_download: OutlineDocumentDownload,
  outline_document_duplicate: OutlineDocumentDuplicate,
  outline_document_remove: OutlineDocumentRemove,
  outline_document_report: OutlineDocumentReport,
  outline_document_search: OutlineDocumentSearch,
  outline_document_text: OutlineDocumentText,
  outline_document: OutlineDocument,
  outline_dots_circle_horizontal: OutlineDotsCircleHorizontal,
  outline_dots_horizontal: OutlineDotsHorizontal,
  outline_dots_vertical: OutlineDotsVertical,
  outline_download: OutlineDownload,
  outline_duplicate: OutlineDuplicate,
  outline_emoji_happy: OutlineEmojiHappy,
  outline_emoji_sad: OutlineEmojiSad,
  outline_exclamation_circle: OutlineExclamationCircle,
  outline_exclamation: OutlineExclamation,
  outline_external_link: OutlineExternalLink,
  outline_eye_off: OutlineEyeOff,
  outline_eye: OutlineEye,
  outline_fast_forward: OutlineFastForward,
  outline_film: OutlineFilm,
  outline_filter: OutlineFilter,
  outline_finger_print: OutlineFingerPrint,
  outline_fire: OutlineFire,
  outline_flag: OutlineFlag,
  outline_folder_add: OutlineFolderAdd,
  outline_folder_download: OutlineFolderDownload,
  outline_folder_open: OutlineFolderOpen,
  outline_folder_remove: OutlineFolderRemove,
  outline_folder: OutlineFolder,
  outline_gift: OutlineGift,
  outline_globe_alt: OutlineGlobeAlt,
  outline_globe: OutlineGlobe,
  outline_hand: OutlineHand,
  outline_hashtag: OutlineHashtag,
  outline_heart: OutlineHeart,
  outline_home: OutlineHome,
  outline_identification: OutlineIdentification,
  outline_inbox_in: OutlineInboxIn,
  outline_inbox: OutlineInbox,
  outline_information_circle: OutlineInformationCircle,
  outline_key: OutlineKey,
  outline_library: OutlineLibrary,
  outline_light_bulb: OutlineLightBulb,
  outline_lightning_bolt: OutlineLightningBolt,
  outline_link: OutlineLink,
  outline_location_marker: OutlineLocationMarker,
  outline_lock_closed: OutlineLockClosed,
  outline_lock_open: OutlineLockOpen,
  outline_login: OutlineLogin,
  outline_logout: OutlineLogout,
  outline_mail_open: OutlineMailOpen,
  outline_mail: OutlineMail,
  outline_map: OutlineMap,
  outline_menu_alt_1: OutlineMenuAlt1,
  outline_menu_alt_2: OutlineMenuAlt2,
  outline_menu_alt_3: OutlineMenuAlt3,
  outline_menu_alt_4: OutlineMenuAlt4,
  outline_menu: OutlineMenu,
  outline_microphone: OutlineMicrophone,
  outline_minus_circle: OutlineMinusCircle,
  outline_minus_sm: OutlineMinusSm,
  outline_minus: OutlineMinus,
  outline_moon: OutlineMoon,
  outline_music_note: OutlineMusicNote,
  outline_newspaper: OutlineNewspaper,
  outline_office_building: OutlineOfficeBuilding,
  outline_paper_airplane: OutlinePaperAirplane,
  outline_paper_clip: OutlinePaperClip,
  outline_pause: OutlinePause,
  outline_pencil_alt: OutlinePencilAlt,
  outline_pencil: OutlinePencil,
  outline_phone_incoming: OutlinePhoneIncoming,
  outline_phone_missed_call: OutlinePhoneMissedCall,
  outline_phone_outgoing: OutlinePhoneOutgoing,
  outline_phone: OutlinePhone,
  outline_photograph: OutlinePhotograph,
  outline_play: OutlinePlay,
  outline_plus_circle: OutlinePlusCircle,
  outline_plus_sm: OutlinePlusSm,
  outline_plus: OutlinePlus,
  outline_presentation_chart_bar: OutlinePresentationChartBar,
  outline_presentation_chart_line: OutlinePresentationChartLine,
  outline_printer: OutlinePrinter,
  outline_puzzle: OutlinePuzzle,
  outline_qrcode: OutlineQRCode,
  outline_question_mark_circle: OutlineQuestionMarkCircle,
  outline_receipt_refund: OutlineReceiptRefund,
  outline_receipt_tax: OutlineReceiptTax,
  outline_refresh: OutlineRefresh,
  outline_reply: OutlineReply,
  outline_rewind: OutlineRewind,
  outline_rss: OutlineRss,
  outline_save_as: OutlineSaveAs,
  outline_save: OutlineSave,
  outline_scale: OutlineScale,
  outline_scissors: OutlineScissors,
  outline_search_circle: OutlineSearchCircle,
  outline_search: OutlineSearch,
  outline_selector: OutlineSelector,
  outline_server: OutlineServer,
  outline_share: OutlineShare,
  outline_shield_check: OutlineShieldCheck,
  outline_shield_exclamation: OutlineShieldExclamation,
  outline_shopping_bag: OutlineShoppingBag,
  outline_shopping_cart: OutlineShoppingCart,
  outline_sort_ascending: OutlineSortAscending,
  outline_sort_descending: OutlineSortDescending,
  outline_sparkles: OutlineSparkles,
  outline_speakerphone: OutlineSpeakerphone,
  outline_star: OutlineStar,
  outline_status_offline: OutlineStatusOffline,
  outline_status_online: OutlineStatusOnline,
  outline_stop: OutlineStop,
  outline_sun: OutlineSun,
  outline_support: OutlineSupport,
  outline_switch_horizontal: OutlineSwitchHorizontal,
  outline_switch_vertical: OutlineSwitchVertical,
  outline_table: OutlineTable,
  outline_tag: OutlineTag,
  outline_template: OutlineTemplate,
  outline_terminal: OutlineTerminal,
  outline_thumb_down: OutlineThumbDown,
  outline_thumb_up: OutlineThumbUp,
  outline_ticket: OutlineTicket,
  outline_translate: OutlineTranslate,
  outline_trash: OutlineTrash,
  outline_trending_down: OutlineTrendingDown,
  outline_trending_up: OutlineTrendingUp,
  outline_truck: OutlineTruck,
  outline_upload: OutlineUpload,
  outline_user_add: OutlineUserAdd,
  outline_user_circle: OutlineUserCircle,
  outline_user_group: OutlineUserGroup,
  outline_user_remove: OutlineUserRemove,
  outline_user: OutlineUser,
  outline_users: OutlineUsers,
  outline_variable: OutlineVariable,
  outline_video_camera: OutlineVideoCamera,
  outline_view_boards: OutlineViewBoards,
  outline_view_grid_add: OutlineViewGridAdd,
  outline_view_grid: OutlineViewGrid,
  outline_view_list: OutlineViewList,
  outline_volume_off: OutlineVolumeOff,
  outline_volume_up: OutlineVolumeUp,
  outline_wifi: OutlineWifi,
  outline_x_circle: OutlineXCircle,
  outline_x: OutlineX,
  outline_zoom_in: OutlineZoomIn,
  outline_zoom_out: OutlineZoomOut,
  solid_academic_cap: SolidAcademicCap,
  solid_adjustments: SolidAdjustments,
  solid_annotation: SolidAnnotation,
  solid_archive: SolidArchive,
  solid_arrow_circle_down: SolidArrowCircleDown,
  solid_arrow_circle_left: SolidArrowCircleLeft,
  solid_arrow_circle_right: SolidArrowCircleRight,
  solid_arrow_circle_up: SolidArrowCircleUp,
  solid_arrow_down: SolidArrowDown,
  solid_arrow_left: SolidArrowLeft,
  solid_arrow_narrow_down: SolidArrowNarrowDown,
  solid_arrow_narrow_left: SolidArrowNarrowLeft,
  solid_arrow_narrow_right: SolidArrowNarrowRight,
  solid_arrow_narrow_up: SolidArrowNarrowUp,
  solid_arrow_right: SolidArrowRight,
  solid_arrow_sm_down: SolidArrowSmDown,
  solid_arrow_sm_left: SolidArrowSmLeft,
  solid_arrow_sm_right: SolidArrowSmRight,
  solid_arrow_sm_up: SolidArrowSmUp,
  solid_arrow_up: SolidArrowUp,
  solid_arrows_expand: SolidArrowsExpand,
  solid_at_symbol: SolidAtSymbol,
  solid_backspace: SolidBackspace,
  solid_badge_check: SolidBadgeCheck,
  solid_ban: SolidBan,
  solid_beaker: SolidBeaker,
  solid_bell: SolidBell,
  solid_book_open: SolidBookOpen,
  solid_bookmark_alt: SolidBookmarkAlt,
  solid_bookmark: SolidBookmark,
  solid_briefcase: SolidBriefcase,
  solid_cake: SolidCake,
  solid_calculator: SolidCalculator,
  solid_calendar: SolidCalendar,
  solid_camera: SolidCamera,
  solid_cash: SolidCash,
  solid_chart_bar: SolidChatBar,
  solid_chart_pie: SolidChartPie,
  solid_chart_square_bar: SolidChartSqareBar,
  solid_chat_alt_2: SolidCatAlt2,
  solid_chat_alt: SolidChatAlt,
  solid_chat: SolidChat,
  solid_check_circle: SolidCheckCircle,
  solid_check: SolidCheck,
  solid_chevron_double_down: SolidChevronDoubleDown,
  solid_chevron_double_left: SolidChevronDoubleLeft,
  solid_chevron_double_right: SolidChevronDoubleRight,
  solid_chevron_double_up: SolidChevronDoubleUp,
  solid_chevron_down: SolidChveronDown,
  solid_chevron_left: SolidChveronLeft,
  solid_chevron_right: SolidChveronRight,
  solid_chevron_up: SolidChveronUp,
  solid_chip: SolidChip,
  solid_clipboard_check: SolidClipboardCheck,
  solid_clipboard_copy: SolidClipboardCopy,
  solid_clipboard_list: SolidClipboardList,
  solid_clipboard: SolidClipboard,
  solid_clock: SolidClock,
  solid_cloud_download: SolidCloudDownload,
  solid_cloud_upload: SolidCloudUpload,
  solid_cloud: SolidCloud,
  solid_code: SolidCode,
  solid_cog: SolidCog,
  solid_collection: SolidCollection,
  solid_color_swatch: SolidColorSwatch,
  solid_credit_card: SolidCreditCard,
  solid_cube_transparent: SolidCubeTransparent,
  solid_cube: SolidCube,
  solid_currency_bangladeshi: SolidCurrencyBangladesh,
  solid_currency_dollar: SolidCurrencyDollar,
  solid_currency_euro: SolidCurrencyEuro,
  solid_currency_pound: SolidCurrencyPound,
  solid_currency_rupee: SolidCurrencyRupee,
  solid_currency_yen: SolidCurrencyYen,
  solid_cursor_click: SolidCursorClick,
  solid_database: SolidDatabase,
  solid_desktop_computer: SolidDesktopComputer,
  solid_device_mobile: SolidDeviceMobile,
  solid_device_tablet: SolidDeviceTablet,
  solid_document_add: SolidDocumentAdd,
  solid_document_download: SolidDocumentDownload,
  solid_document_duplicate: SolidDocumentDuplicate,
  solid_document_remove: SolidDocumentRemove,
  solid_document_report: SolidDocumentReport,
  solid_document_search: SolidDocumentSearch,
  solid_document_text: SolidDocumentText,
  solid_document: SolidDocument,
  solid_dots_circle_horizontal: SolidDotsCircleHorizontal,
  solid_dots_horizontal: SolidDotsHorizontal,
  solid_dots_vertical: SolidDotsVertical,
  solid_download: SolidDownload,
  solid_duplicate: SolidDuplicate,
  solid_emoji_happy: SolidEmojiHappy,
  solid_emoji_sad: SolidEmojiSad,
  solid_exclamation_circle: SolidExclamationCircle,
  solid_exclamation: SolidExclamation,
  solid_external_link: SolidExternalLink,
  solid_eye_off: SolidEyeOff,
  solid_eye: SolidEye,
  solid_fast_forward: SolidFastForward,
  solid_film: SolidFilm,
  solid_filter: SolidFilter,
  solid_finger_print: SolidFingerPrint,
  solid_fire: SolidFire,
  solid_flag: SolidFlag,
  solid_folder_add: SolidFolderAdd,
  solid_folder_download: SolidFolderDownload,
  solid_folder_open: SolidFolderOpen,
  solid_folder_remove: SolidFolderRemove,
  solid_folder: SolidFolder,
  solid_gift: SolidGift,
  solid_globe_alt: SolidGlobeAlt,
  solid_globe: SolidGlobe,
  solid_hand: SolidHand,
  solid_hashtag: SolidHashtag,
  solid_heart: SolidHeart,
  solid_home: SolidHome,
  solid_identification: SolidIdentification,
  solid_inbox_in: SolidInboxIn,
  solid_inbox: SolidInbox,
  solid_information_circle: SolidInformationCircle,
  solid_key: SolidKey,
  solid_library: SolidLibrary,
  solid_light_bulb: SolidLightBulb,
  solid_lightning_bolt: SolidLightningBolt,
  solid_link: SolidLink,
  solid_location_marker: SolidLocationMarker,
  solid_lock_closed: SolidLockClosed,
  solid_lock_open: SolidLockOpen,
  solid_login: SolidLogin,
  solid_logout: SolidLogout,
  solid_mail_open: SolidMailOpen,
  solid_mail: SolidMail,
  solid_map: SolidMap,
  solid_menu_alt_1: SolidMenuAlt1,
  solid_menu_alt_2: SolidMenuAlt2,
  solid_menu_alt_3: SolidMenuAlt3,
  solid_menu_alt_4: SolidMenuAlt4,
  solid_menu: SolidMenu,
  solid_microphone: SolidMicrophone,
  solid_minus_circle: SolidMinusCircle,
  solid_minus_sm: SolidMinusSm,
  solid_minus: SolidMinus,
  solid_moon: SolidMoon,
  solid_music_note: SolidMusicNote,
  solid_newspaper: SolidNewspaper,
  solid_office_building: SolidOfficeBuilding,
  solid_paper_airplane: SolidPaperAirplane,
  solid_paper_clip: SolidPaperClip,
  solid_pause: SolidPause,
  solid_pencil_alt: SolidPencilAlt,
  solid_pencil: SolidPencil,
  solid_phone_incoming: SolidPhoneIncoming,
  solid_phone_missed_call: SolidPhoneMissedCall,
  solid_phone_outgoing: SolidPhoneOutgoing,
  solid_phone: SolidPhone,
  solid_photograph: SolidPhotograph,
  solid_play: SolidPlay,
  solid_plus_circle: SolidPlusCircle,
  solid_plus_sm: SolidPlusSm,
  solid_plus: SolidPlus,
  solid_presentation_chart_bar: SolidPresentationChartBar,
  solid_presentation_chart_line: SolidPresentationChartLine,
  solid_printer: SolidPrinter,
  solid_puzzle: SolidPuzzle,
  solid_qrcode: SolidQRCode,
  solid_question_mark_circle: SolidQuestionMarkCircle,
  solid_receipt_refund: SolidReceiptRefund,
  solid_receipt_tax: SolidReceiptTax,
  solid_refresh: SolidRefresh,
  solid_reply: SolidReply,
  solid_rewind: SolidRewind,
  solid_rss: SolidRss,
  solid_save_as: SolidSaveAs,
  solid_save: SolidSave,
  solid_scale: SolidScale,
  solid_scissors: SolidScissors,
  solid_search_circle: SolidSearchCircle,
  solid_search: SolidSearch,
  solid_selector: SolidSelector,
  solid_server: SolidServer,
  solid_share: SolidShare,
  solid_shield_check: SolidShieldCheck,
  solid_shield_exclamation: SolidShieldExclamation,
  solid_shopping_bag: SolidShoppingBag,
  solid_shopping_cart: SolidShoppingCart,
  solid_sort_ascending: SolidSortAscending,
  solid_sort_descending: SolidSortDescending,
  solid_sparkles: SolidSparkles,
  solid_speakerphone: SolidSpeakerphone,
  solid_star: SolidStar,
  solid_status_offline: SolidStatusOffline,
  solid_status_online: SolidStatusOnline,
  solid_stop: SolidStop,
  solid_sun: SolidSun,
  solid_support: SolidSupport,
  solid_switch_horizontal: SolidSwitchHorizontal,
  solid_switch_vertical: SolidSwitchVertical,
  solid_table: SolidTable,
  solid_tag: SolidTag,
  solid_template: SolidTemplate,
  solid_terminal: SolidTerminal,
  solid_thumb_down: SolidThumbDown,
  solid_thumb_up: SolidThumbUp,
  solid_ticket: SolidTicket,
  solid_translate: SolidTranslate,
  solid_trash: SolidTrash,
  solid_trending_down: SolidTrendingDown,
  solid_trending_up: SolidTrendingUp,
  solid_truck: SolidTruck,
  solid_upload: SolidUpload,
  solid_user_add: SolidUserAdd,
  solid_user_circle: SolidUserCircle,
  solid_user_group: SolidUserGroup,
  solid_user_remove: SolidUserRemove,
  solid_user: SolidUser,
  solid_users: SolidUsers,
  solid_variable: SolidVariable,
  solid_video_camera: SolidVideoCamera,
  solid_view_boards: SolidViewBoards,
  solid_view_grid_add: SolidViewGridAdd,
  solid_view_grid: SolidViewGrid,
  solid_view_list: SolidViewList,
  solid_volume_off: SolidVolumeOff,
  solid_volume_up: SolidVolumeUp,
  solid_wifi: SolidWifi,
  solid_x_circle: SolidXCircle,
  solid_x: SolidX,
  solid_zoom_in: SolidZoomIn,
  solid_zoom_out: SolidZoomOut,
  logo_facebook: LogoFacebook,
  logo_instagram: LogoInstagram,
  logo_twitter: LogoTwitter,
  logo_tiktok: LogoTikTok,
  logo_snapchat: LogoSnapchat,
  site_logo: SiteLogo,
};

const Icon = ({ name, classNames, variation }) => {
  const iconName = `${variation}_${name}`;
  const Svg = iconComponents[iconName];
  return (
    <i>
      <span className="sr-only">{`Icon: ${variation} ${name}`}</span>
      <Svg className={classNames} />
    </i>
  );
};

export default Icon;
