import React, { Fragment } from "react";

interface ImageBlockProps {
  imgUrl: string;
  theme: {
    classNames?: string;
    imageClassNames?: string;
  };
}

const ImageBlock = ({
  imgUrl,
  theme: { classNames, imageClassNames },
}: ImageBlockProps) => {
  return (
    <section className={`ImageBlock ${classNames}`}>
      <img src={imgUrl} className={imageClassNames} alt="" />
    </section>
  );
};

export default ImageBlock;
