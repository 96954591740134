import React, { HTMLAttributes } from "react";

interface SpotifyProps extends HTMLAttributes<HTMLIFrameElement> {
  [key: string]: any;

  spotifyUri: string;
  theme: {
    wide?: boolean;
    width?: number | string;
    height?: number | string;
    frameBorder?: number | string;
    classNames: string;
  };
  allow?: string;
}

const SpotifyEmbed = ({
  spotifyUri,
  theme: {
    wide = false,
    width = wide ? "100%" : 300,
    height = wide ? 80 : 380,
    frameBorder = 0,
    classNames,
  },
  allow = "encrypted-media",
  ...props
}: SpotifyProps) => {
  const url = new URL(spotifyUri);
  // https://open.spotify.com/track/1KFxcj3MZrpBGiGA8ZWriv?si=f024c3aa52294aa1
  return (
    <iframe
      title="Spotify Web Player"
      src={`https://open.spotify.com/embed${url.pathname}`}
      width={width}
      height={height}
      frameBorder={frameBorder}
      allow={allow}
      className={classNames}
      {...props}
    />
  );
};

export default SpotifyEmbed;
