import React, { ReactNode } from "react";

interface SplitImageFadeBlockBackgroundProps {
  theme: {
    imageClassNames: string;
    bgTheme: string;
    darkBgTheme: string;
  };
  imageUrl: string;
}

const SplitImageFadeBlockBackgroundImage = ({
  imageUrl,
  theme: { imageClassNames, bgTheme, darkBgTheme },
}: SplitImageFadeBlockBackgroundProps) => {
  return (
    <div
      className={`SplitImageFadeBlockBackgroundImage h-80 w-full absolute bottom-0 xl:inset-0 xl:h-full ${imageClassNames}`}
    >
      <div className="h-full w-full xl:grid xl:grid-cols-2">
        <div className="h-full xl:relative xl:col-start-2">
          <img
            className={`h-full w-full object-cover opacity-50 xl:absolute xl:inset-0`}
            src={imageUrl}
            alt="People working on laptops"
          />
          <div
            aria-hidden="true"
            className={`absolute inset-x-0 top-0 h-32 bg-gradient-to-b from-${bgTheme} dark:from-${darkBgTheme} xl:inset-y-0 xl:left-0 xl:h-full xl:w-32 xl:bg-gradient-to-r`}
          />
        </div>
      </div>
    </div>
  );
};

interface PitchItemProps {
  title: string;
  text: string;
  theme: {
    itemTitleClasses: string;
    itemSubtitleClasses: string;
  };
}

const PitchItem = ({
  title,
  text,
  theme: { itemTitleClasses, itemSubtitleClasses },
}: PitchItemProps) => {
  return (
    <p className="MetricItem">
      <span className={itemTitleClasses}>{title}</span>
      <span className={itemSubtitleClasses}>{text}</span>
    </p>
  );
};

interface SplitImageFadeBlockTitleBlockProps {
  label: string;
  title: string;
  subtitle: string;
  children: ReactNode[];
  theme: {
    titleClassNames: string;
    labelClassNames: string;
    subtitleClassNames: string;
  };
}

const SplitImageFadeBlockTitleBlock = ({
  label,
  title,
  subtitle,
  children,
  theme: { titleClassNames, labelClassNames, subtitleClassNames },
}: SplitImageFadeBlockTitleBlockProps) => {
  return (
    <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:max-w-7xl lg:px-8 xl:grid xl:grid-cols-2 xl:grid-flow-col-dense xl:gap-x-8">
      <div className="relative pt-12 pb-64 sm:pt-24 sm:pb-64 xl:col-start-1 xl:pb-24">
        <h2 className={`Label ${labelClassNames}`}>{label}</h2>
        <p className={`Title ${titleClassNames}`}>{title}</p>
        <p className={`Subtitle ${subtitleClassNames}`}>{subtitle}</p>
        <div className="mt-12 grid grid-cols-1 gap-y-12 gap-x-6 sm:grid-cols-2">
          {children}
        </div>
      </div>
    </div>
  );
};

interface SplitImageFadeBlockProps {
  label: string;
  title: string;
  subtitle: string;
  imageUrl: string;
  pitchItems: PitchItemProps[];
  theme: {
    classNames: string;
    titleClassNames: string;
    subtitleClassNames: string;
    labelClassNames: string;
    imageClassNames: string;
    itemTitleClasses: string;
    itemSubtitleClasses: string;
    bgTheme: string;
    darkBgTheme: string;
  };
}

interface PitchItemProps {
  title: string;
  text: string;
}

const SplitImageFadeBlock = ({
  label,
  title,
  subtitle,
  imageUrl,
  theme: {
    classNames,
    titleClassNames,
    subtitleClassNames,
    labelClassNames,
    imageClassNames,
    itemTitleClasses,
    itemSubtitleClasses,
    bgTheme,
    darkBgTheme,
  },
  pitchItems,
}: SplitImageFadeBlockProps): JSX.Element => {
  return (
    <section className={`SplitImageFadeBlock ${classNames}`}>
      <SplitImageFadeBlockBackgroundImage
        imageUrl={imageUrl}
        theme={{
          imageClassNames,
          bgTheme,
          darkBgTheme,
        }}
      />
      <SplitImageFadeBlockTitleBlock
        title={title}
        label={label}
        subtitle={subtitle}
        theme={{
          labelClassNames,
          titleClassNames,
          subtitleClassNames,
        }}
      >
        {pitchItems &&
          pitchItems.map((item: PitchItemProps) => (
            <PitchItem
              key={item.id}
              title={item.title}
              text={`${item.text}`}
              theme={{ itemSubtitleClasses, itemTitleClasses }}
            />
          ))}
      </SplitImageFadeBlockTitleBlock>
    </section>
  );
};

export default SplitImageFadeBlock;
