import React, { useState } from "react";
import Form from "@rjsf/core";
import Typography from "./Typography";

interface DataCaptureBlockProps {
  theme: {
    classNames: string;
    successTitleClassNames: string;
    successSubtitleClassNames: string;
  };
  successMessage: {
    title: string;
    subtitle: string;
  };
  uiSchema: any;
  schema: any;
  schemaDefaults: any;
  formHandlerConfig: {
    method: string;
    uri: string;
  };
}

const DataCaptureBlock = ({
  theme: { classNames, successTitleClassNames, successSubtitleClassNames },
  schema,
  uiSchema,
  schemaDefaults,
  formHandlerConfig,
  successMessage,
}: DataCaptureBlockProps) => {
  const [hasSubmittedFormSuccessfully, setHasSubmittedFormSuccessfully] =
    useState(false);

  const submitHandler = (evt) => {
    const { formData } = evt;

    fetch(formHandlerConfig.uri, {
      method: formHandlerConfig.method,
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      body: JSON.stringify(formData),
    })
      .then((response) => response.json())
      .then((data) => {
        setHasSubmittedFormSuccessfully(true);
      })
      .catch((error) => {
        setHasSubmittedFormSuccessfully(false);
      });
  };

  return (
    <section className={classNames}>
      {hasSubmittedFormSuccessfully ? (
        <SuccessMessage
          title={successMessage.title}
          subtitle={successMessage.subtitle}
          theme={{
            titleClassNames: successTitleClassNames,
            subtitleClassNames: successSubtitleClassNames,
          }}
        />
      ) : (
        <Form
          schema={schema}
          uiSchema={uiSchema}
          formData={schemaDefaults}
          onSubmit={submitHandler}
        />
      )}
    </section>
  );
};

interface SuccessMessageProps {
  title: string;
  subtitle: string;
  theme: {
    titleClassNames: string;
    subtitleClassNames: string;
  };
}
const SuccessMessage = ({ title, subtitle, theme }) => {
  return (
    <>
      <Typography
        componentType="h2"
        theme={{ classNames: theme.titleClassNames }}
        value={title}
      />
      <Typography
        componentType="h3"
        theme={{ classNames: theme.subtitleClassNames }}
        value={subtitle}
      />
    </>
  );
};

export default DataCaptureBlock;
