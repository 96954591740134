import React from "react";

// const SVGAdornment = () => {
//   return (
//     <div aria-hidden="true" className="hidden sm:block">
//       <div className="absolute inset-y-0 left-0 w-1/2 bg-gray-50 rounded-r-3xl"></div>
//       <svg
//         className="absolute top-8 left-1/2 -ml-3"
//         width="404"
//         height="392"
//         fill="none"
//         viewBox="0 0 404 392"
//       >
//         <defs>
//           <pattern
//             id="8228f071-bcee-4ec8-905a-2a059a2cc4fb"
//             x="0"
//             y="0"
//             width="20"
//             height="20"
//             patternUnits="userSpaceOnUse"
//           >
//             <rect
//               x="0"
//               y="0"
//               width="4"
//               height="4"
//               className="text-gray-200"
//               fill="currentColor"
//             />
//           </pattern>
//         </defs>
//         <rect
//           width="404"
//           height="392"
//           fill="url(#8228f071-bcee-4ec8-905a-2a059a2cc4fb)"
//         />
//       </svg>
//     </div>
//   );
// };

// const SVGAdornment2 = () => {
//   return (
//     <div
//       aria-hidden="true"
//       className="absolute inset-0 -mt-72 sm:-mt-32 md:mt-0"
//     >
//       <svg
//         className="absolute inset-0 h-full w-full"
//         preserveAspectRatio="xMidYMid slice"
//         xmlns="http://www.w3.org/2000/svg"
//         fill="none"
//         viewBox="0 0 1463 360"
//       >
//         <path
//           className="text-rose-400 text-opacity-40"
//           fill="currentColor"
//           d="M-82.673 72l1761.849 472.086-134.327 501.315-1761.85-472.086z"
//         />
//         <path
//           className="text-rose-600 text-opacity-40"
//           fill="currentColor"
//           d="M-217.088 544.086L1544.761 72l134.327 501.316-1761.849 472.086z"
//         />
//       </svg>
//     </div>
//   );
// };

interface CTATitleProps {
  title: string;
  theme: {
    classNames: string;
  };
}

const CTATitle = ({ title, theme: { classNames } }: CTATitleProps) => {
  return <h2 className={`CTA__Title ${classNames}`}>{title}</h2>;
};

interface CTASubTitleProps {
  subtitle?: string;
  theme: {
    classNames: string;
  };
}

const CTASubTitle = ({ subtitle, theme: { classNames } }: CTASubTitleProps) => {
  return <p className={`CTA__SubTitle ${classNames}`}>{subtitle}</p>;
};

const CTAActionRow = () => {
  return (
    <div className="CTA__ActionRow">
      <form action="#" className="flex ">
        <div className="min-w-0 flex-1">
          <label htmlFor="cta-email" className="sr-only">
            Email address
          </label>
          <input
            id="cta-email"
            type="email"
            className="block w-full border border-transparent rounded-md px-5 py-3 text-base text-gray-900 placeholder-gray-500 shadow-sm focus:outline-none focus:border-transparent focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-rose-500"
            placeholder="Enter your email"
          />
        </div>
        <div className="mt-4 sm:mt-0 sm:ml-3">
          <button
            type="submit"
            className="block w-full rounded-md border border-transparent px-5 py-3 bg-gray-900 text-base font-medium text-white shadow hover:bg-black focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-rose-500 sm:px-10"
          >
            Notify me
          </button>
        </div>
      </form>
    </div>
  );
};

interface CTAProps {
  theme: {
    classNames: string;
    titleClassNames: string;
    subtitleClassNames: string;
    textContainerClassNames: string;
    backgroundClassNames: string;
  };
  title: string;
  subtitle?: string;
}

const CTA = ({
  title,
  subtitle,
  theme: {
    classNames,
    backgroundClassNames,
    textContainerClassNames,
    titleClassNames,
    subtitleClassNames,
  },
}: CTAProps) => {
  // const theme = {
  //   align: "CENTER",
  //   background: "PRIMARY",
  //   text: "WHITE",
  //   variation: "NEUMORPHIC",
  // };

  return (
    <section className={`CTA ${classNames}`}>
      <div className="CTA__container">
        <div className={`CTA__background ${backgroundClassNames}`}>
          <div className="relative">
            <div className={textContainerClassNames}>
              <CTATitle title={title} theme={{ classNames: titleClassNames }} />
              <CTASubTitle
                subtitle={subtitle}
                theme={{ classNames: subtitleClassNames }}
              />
            </div>
            <CTAActionRow />
          </div>
        </div>
      </div>
    </section>
  );
};

export default CTA;
